import React, { useEffect, useRef, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

const CodesCellText = ({ codes }: { codes: { [key: string]: string | string[] } }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const isEllipsisActive = (e: HTMLDivElement) => {
    return e.offsetWidth < e.scrollWidth;
  };
  useEffect(() => {
    const element = textRef?.current;
    if (element) {
      setIsOverflowing(isEllipsisActive(element));
    }
  }, []);

  if (!codes || Object.keys(codes).length === 0) {
    return <></>;
  }
  let disp = "";

  disp = Object.entries(codes)
    .map(([key, value]) => `${key} : ${value}`)
    .join(", ");

  return (
    <>
      {isOverflowing ? (
        <div style={{ display: "flex" }}>
          <div
            ref={textRef}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {disp}
          </div>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="button-tooltip">
                <>
                  {Object.entries(codes).map(([key, value], index) => {
                    return (
                      <div style={{ textAlign: "left", width: "fit-content" }} key={key}>
                        {`-  ${key} : ${Array.isArray(value) ? value.join(" , ") : value}`}
                      </div>
                    );
                  })}
                </>
              </Tooltip>
            }
          >
            <Badge
              pill
              className="table-pill"
              style={{
                width: "30px",
                height: "20px",
                marginLeft: "8px",
              }}
            >
              {Object.keys(codes).length}
            </Badge>
          </OverlayTrigger>
        </div>
      ) : (
        <>
          <div
            ref={textRef}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {disp}
          </div>
        </>
      )}
    </>
  );
};

export default CodesCellText;
