import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {DistributionRoyaltiesFiltersParams, DistributionRoyaltiesSearchParams, DistributionRoyaltiesState, DistributionsAmountPayload, DistributionsWorksPayload, ExploitationTerritoriesPayload, MultiSelectAutoCompleteItem} from '../types/distributionsRoyaltiesTypes';
import {
	getDistributions,
	getMapDataDistributionRoyalties,
	getDistributionsAmount,
	getCatalogues,
	getExploitationPeriods,
	getWorksSuggest,
	getCatalogsSuggest,
	getRightsTypes,
	getWorks,
} from '../api/DistributionRoyaltiesAPI';


export const initialState: DistributionRoyaltiesState = {
	status: {
	  distributions: -1,
	  coads: -2,
	  works: -2,
	  rightsTypes: -2,
	  catalogues: -2,
	  exploitationTerritories: -2,
	  offerExploitationPeriods: -2,
	  dspExploitationPeriods: -2,
	  amount: -2,
	  worksSuggest: -2,
	  catalogsSuggest: -2,
	},
	filters: {
	  distributions: [],
	  coads: [],
	},
	searchParams: {
	  distribution: -1,
	  coad: -1,
	  works: [],
	  catalogs: [],
	},
	content: {
	  currentBalance: 0,
	  distributionWorks: [],
	  rightsTypes: [],
	  rightsTypesTotal: 0,
	  catalogues: [],
	  exploitationTerritories: { amount: 0, exploitationTerritories: [] },
	  offerExploitationPeriods: { exploitationPeriods: [] },
	  dspExploitationPeriods: { exploitationPeriods: [] },
	  worksSuggest: [],
	  catalogsSuggest: [],
	},
	prevSearchParams: {
	  distribution: '-',
	},
  }

export const fetchDistributionsAsync = createAsyncThunk(
	'distributionRoyalties/distributions/load',
	async (params: { mandatorId: string, startDate: string }, { rejectWithValue }) => {
		try {
			return await getDistributions(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchMapDataDistributionRoyaltiesAsync = createAsyncThunk(
	'distributionRoyalties/exploitation-territories/fetch',
	async (params: ExploitationTerritoriesPayload, { rejectWithValue }) => {
		try {
			const res = await getMapDataDistributionRoyalties(params);
			return res
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchDistributionsAmountAsync = createAsyncThunk(
	'distributionRoyalties/amount/fetch',
	async (params: DistributionsAmountPayload, { rejectWithValue }) => {
		try {
			return await getDistributionsAmount(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchCataloguesAsync = createAsyncThunk(
	'distributionRoyalties/catalogues/fetch',
	async (params: any, { rejectWithValue }) => {
		try {
			return await getCatalogues(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchOfferExploitationPeriodsAsync = createAsyncThunk(
	'distributionRoyalties/offerExploitationPeriods/fetch',
	async (params: DistributionRoyaltiesSearchParams, { rejectWithValue }) => {
		try {
			return await getExploitationPeriods(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchDspExploitationPeriodsAsync = createAsyncThunk(
	'distributionRoyalties/dspExploitationPeriods/fetch',
	async (params: DistributionRoyaltiesSearchParams, { rejectWithValue }) => {
		try {
			return await getExploitationPeriods(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchWorksSuggestAsync = createAsyncThunk(
	'distributionRoyalties/worksSuggest/fetch',
	async (params: DistributionRoyaltiesFiltersParams, { rejectWithValue }) => {
		try {
			return await getWorksSuggest(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchCatalogsSuggestAsync = createAsyncThunk(
	'distributionRoyalties/catalogsSuggest/fetch',
	async (params: DistributionRoyaltiesFiltersParams, { rejectWithValue }) => {
		try {
			return await getCatalogsSuggest(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchRightsTypesAsync = createAsyncThunk(
	'distributionRoyalties/rights-types/fetch',
	async (params: DistributionRoyaltiesSearchParams, { rejectWithValue }) => {
		try {
			return await getRightsTypes(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchDistributionsWorksAsync = createAsyncThunk(
	'distributionRoyalties/works/fetch',
	async (params: DistributionsWorksPayload, { rejectWithValue }) => {
		try {
			return await getWorks(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);


export const distributionRoyaltiesSlice = createSlice({
	name: 'distributionRoyalties',
	initialState,
	reducers: {
		resetDistributionRoyaltiesState:(state) => {
			return initialState
		},
		loadPreviousSearchParams:(state: DistributionRoyaltiesState) => {
			if (state.filters?.distributions.length) {
				const distribution = state.filters?.distributions.find((item) => item.number === state.searchParams.distribution) || { date: '', number: 0 }
				state.prevSearchParams = {
					distribution: distribution.date + ' N°' + distribution.number,
				}
			}
		},
		loadSelectedDistribution:(state: DistributionRoyaltiesState, action) => {
			state.searchParams.distribution = action.payload
		},
		sortCatalogs:(state, action) => {
			state.content.catalogues = action.payload
		},
		loadSelectedCatalogs:(state, action) => {
			state.searchParams.catalogs = action.payload
		},
		loadSelectedWorks:(state, action) => {
			state.searchParams.works = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDistributionsAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.distributions = -1;
			})
			.addCase(fetchDistributionsAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.filters.distributions = action.payload.data.distributions
				state.searchParams.distribution = state.filters.distributions[0].number
				// Keep only the existing items in the searchParams
				if (state.filters.distributions?.length === 0) {
					state.searchParams.distribution = 0
				} else {
					if (state.filters.distributions.length &&
						!state.filters.distributions
							.map((itm) => itm.number)
							.includes(state.searchParams.distribution)
					) {
						state.searchParams.distribution = 0
					}
				}

				const distribution = state.filters.distributions.find(
					(item) => item.number === state.searchParams.distribution
				  ) || { date: '', number: 0 }
				  state.prevSearchParams = {
					distribution: distribution.date + ' N°' + distribution.number,
				  }

				state.status.distributions = 200
			})
			.addCase(fetchDistributionsAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						distributions: action.payload.statusCode
					}
				};
			})
			// fetch catalogue
			.addCase(fetchCataloguesAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.catalogues = -1;
			})
			.addCase(fetchCataloguesAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.catalogues = action.payload.data?.originalCatalogs ? action.payload.data.originalCatalogs : []
				state.status.catalogues = 200
			})
			.addCase(fetchCataloguesAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						catalogues: action.payload.statusCode
					}
				};
			})
			// fetch rightTypes
			.addCase(fetchRightsTypesAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.rightsTypes = -1;
			})
			.addCase(fetchRightsTypesAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.rightsTypes = action.payload.data?.rightsTypes ? action.payload.data.rightsTypes : []
				state.content.rightsTypesTotal = action.payload.data?.generatedAmount ? action.payload.data.generatedAmount : 0
				state.status.rightsTypes = 200
			})
			.addCase(fetchRightsTypesAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						rightsTypes: action.payload.statusCode
					}
				};
			})
			// fetch amount
			.addCase(fetchDistributionsAmountAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.amount = -1;
			})
			.addCase(fetchDistributionsAmountAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.currentBalance = action.payload.data?.amount ? action.payload.data.amount : 0
				state.status.amount = 200
			})
			.addCase(fetchDistributionsAmountAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						amount: action.payload.statusCode
					}
				};
			})
			// fetch map territories
			.addCase(fetchMapDataDistributionRoyaltiesAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.exploitationTerritories = -1;
			})
			.addCase(fetchMapDataDistributionRoyaltiesAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.exploitationTerritories.amount = action.payload.data?.amount ? action.payload.data.amount : 0
				state.content.exploitationTerritories.exploitationTerritories = action.payload.data?.exploitation_territories ? action.payload.data.exploitation_territories : []
				state.status.exploitationTerritories = 200
			})
			.addCase(fetchMapDataDistributionRoyaltiesAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						exploitationTerritories: action.payload.statusCode
					}
				};
			})
			// fetch offer exploitations
			.addCase(fetchOfferExploitationPeriodsAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.offerExploitationPeriods = -1;
			})
			.addCase(fetchOfferExploitationPeriodsAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.offerExploitationPeriods = action.payload.data ? action.payload.data : { exploitationPeriods: [] }
				state.status.offerExploitationPeriods = 200
			})
			.addCase(fetchOfferExploitationPeriodsAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						offerExploitationPeriods: action.payload.statusCode
					}
				};
			})
			// fetch DSP exploitations
			.addCase(fetchDspExploitationPeriodsAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.dspExploitationPeriods = -1;
			})
			.addCase(fetchDspExploitationPeriodsAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.dspExploitationPeriods = action.payload.data ? action.payload.data : { exploitationPeriods: [] }
				state.status.dspExploitationPeriods = 200
			})
			.addCase(fetchDspExploitationPeriodsAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						dspExploitationPeriods: action.payload.statusCode
					}
				};
			})
			// fetch works
			.addCase(fetchDistributionsWorksAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.works = -1;
			})
			.addCase(fetchDistributionsWorksAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				state.content.distributionWorks = action.payload.data?.works ? action.payload.data.works : []
				state.status.works = 200
			})
			.addCase(fetchDistributionsWorksAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						works: action.payload.statusCode
					}
				};
			})
			// suggest works
			.addCase(fetchWorksSuggestAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.worksSuggest = -1;
			})
			.addCase(fetchWorksSuggestAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				if (action.payload.data?.works) state.content.worksSuggest = action.payload.data.works
				state.status.worksSuggest = 200
			})
			.addCase(fetchWorksSuggestAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						worksSuggest: action.payload.statusCode
					}
				};
			})
			// suggest catalogs
			.addCase(fetchCatalogsSuggestAsync.pending, (state: DistributionRoyaltiesState) => {
				state.status.catalogsSuggest = -1;
			})
			.addCase(fetchCatalogsSuggestAsync.fulfilled, (state: DistributionRoyaltiesState, action: any) => {
				if (action.payload.data?.originalCatalogs) state.content.catalogsSuggest = action.payload.data.originalCatalogs
				state.status.catalogsSuggest = 200
			})
			.addCase(fetchCatalogsSuggestAsync.rejected, (state: DistributionRoyaltiesState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						catalogsSuggest: action.payload.statusCode
					}
				};
			})
	},
});
export const {
	resetDistributionRoyaltiesState,
	loadPreviousSearchParams,
	loadSelectedDistribution,
	loadSelectedCatalogs,
	loadSelectedWorks,
	sortCatalogs
} = distributionRoyaltiesSlice.actions;

export default distributionRoyaltiesSlice.reducer;
