import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import { getReactQueryStatus } from "./utils";
import {
  deleteAdvancedSearchExport,
  getAdvancedSearchExportsList,
  getLicenses,
  registerExport,
} from "../api/advancedSearchApi";
import {
  AdvancedSearchExport,
  AdvancedSearchLicenses,
  PostAdvancedSearchRequestPayload,
} from "../types/advancedSearchTypes";

const serviceName = "advancedSearch";
const portalName = "webso";

export const useQueryLicenses = (mandatorId: string | undefined) => {
  const { data, isFetching, isError } = useQuery({
    queryKey: [portalName, serviceName, "licenses", mandatorId],
    queryFn: () => getLicenses({ mandatorId: mandatorId }),
    enabled: !!mandatorId,
  });
  const status = getReactQueryStatus(data, isFetching, isError);
  let returnData: AdvancedSearchLicenses = { dsp: [], territory: [] };

  if (status === 200 && data?.data) {
    returnData = data.data;
  }

  return { returnData, status };
};

export const useQueryGetExports = (societyId: string | undefined) => {
  const { data, isFetching, refetch, isError } = useQuery({
    queryKey: [portalName, serviceName, "exports-list", societyId],
    queryFn: () => getAdvancedSearchExportsList({ sisterSocietyId: societyId }),
    enabled: societyId !== undefined,
    refetchInterval: (data, query) => {
      if (data?.data?.some((exportRow: AdvancedSearchExport) => exportRow.status === -1)) {
        return 3500;
      }
      return false;
    },
  });

  let status = getReactQueryStatus(data, isFetching, isError);
  if (data?.data) {
    status = 200;
  }
  return { data, status, refetch, isFetching };
};

export const useAdvancedSearchPostMutation = (
  queryClient: QueryClient,
  societyId: string | undefined
) => {
  const { isLoading, mutate, isError } = useMutation({
    mutationFn: (payload: PostAdvancedSearchRequestPayload) =>
      registerExport(payload, { societyId: societyId }),

    onSettled: (data) => {
      const previousExports = queryClient.getQueryData<{
        data: AdvancedSearchExport[];
      }>([portalName, serviceName, "exports-list", societyId]);
      if (data && previousExports?.data) {
        queryClient.setQueryData([portalName, serviceName, "exports-list", societyId], {
          data: [data.data, ...previousExports.data],
        });
      } else if (data) {
        queryClient.setQueryData([portalName, serviceName, "exports-list", societyId], {
          data: [data.data],
        });
      }
    },
  });
  return { isError, isLoading, mutate };
};
export const useAdvancedSearchDeleteMutation = (
  queryClient: QueryClient,
  sisterSocietyId: string | undefined
) => {
  const { isError, isLoading, mutate } = useMutation({
    mutationFn: (id: string) => deleteAdvancedSearchExport(id),
    onMutate: async (id) => {
      const previousExports = queryClient.getQueryData<{
        data: AdvancedSearchExport[];
      }>([portalName, serviceName, "exports-list", sisterSocietyId]);

      if (previousExports) {
        const updatedItems = previousExports.data.filter((item) => item.export_id !== id);
        queryClient.setQueryData([portalName, serviceName, "exports-list", sisterSocietyId], {
          data: updatedItems,
        });
      }
    },
    onSettled: () => {
      queryClient.refetchQueries([portalName, serviceName, "exports-list", sisterSocietyId]);
    },
  });
  return { isError, isLoading, mutate };
};
