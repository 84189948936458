import React, { useCallback } from "react";
import "./filtersComponent.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { tag } from "../../helpers/PianoAnalyticsService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ApplyButtonComponent = ({
  disabled,
  onClickHandler,
  buttonText = "search-buttons-label",
  tagLabel,
  primeAnalyticsFiltersObject,
  hasHover,
  hoverText,
}: ButtonProps) => {
  const { t } = useTranslation();
  const onClickHandlerFunction = (e: any) => {
    tag.paSendActionClick(preparePianoAnalyticsAttributesObject());
    onClickHandler();
  };
  const mandatorId = useAppSelector((state) => state.appContext.mandator);
  const sisterSocietyId = useAppSelector((state) => state.appContext.selectedSisterSociety.id);
  const sisterSocietyLabel = useAppSelector((state) => state.appContext.selectedSisterSociety.name);

  const preparePianoAnalyticsAttributesObject = useCallback(() => {
    return {
      ...primeAnalyticsFiltersObject,
      mandator_id: mandatorId,
      sister_society_id: sisterSocietyId,
      sister_society_label: sisterSocietyLabel,
      click: t(buttonText),
      click_chapter1: tagLabel ? t(tagLabel) : "",
    };
  }, [primeAnalyticsFiltersObject]);

  return (
    <>
      {hasHover ? (
        <OverlayTrigger
          placement={"bottom"}
          overlay={<Tooltip id="button-tooltip">{hoverText}</Tooltip>}
        >
          <button
            disabled={disabled}
            onClick={onClickHandlerFunction}
            className={"apply-button py-1"}
          >
            <div className={"px-3 py-1"}>{t(buttonText)}</div>
          </button>
        </OverlayTrigger>
      ) : (
        <button
          disabled={disabled}
          onClick={onClickHandlerFunction}
          className={"apply-button py-1"}
        >
          <div className={"px-3 py-1"}>{t(buttonText)}</div>
        </button>
      )}
    </>
  );
};
interface ButtonProps {
  tagLabel?: string;
  onClickHandler: Function;
  disabled: boolean;
  buttonText?: string;
  primeAnalyticsFiltersObject?: Object;
  hoverText?: string;
  hasHover?: boolean;
}
export default ApplyButtonComponent;
