import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./en/translations.json";
import translationFR from "./fr/translations.json";
import SidenavItemsEN from "./en/sidenav-items.json";
import SidenavItemsFR from "./fr/sidenav-items.json";
import advancedSearchEN from "./en/advanced-search.json";
import advancedSearchFR from "./fr/advanced-search.json";
import { merge as _merge } from "lodash-es";

const defaultLanguage = "fr";
const availableLangs = ["fr", "en"];

const detectionOptions = {
  // order and from where user language should be detected
  order: ["querystring"],
  // keys or params to lookup language from
  lookupQuerystring: "lang",
  lookupLocalStorage: "lang",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

// the translations
const resources = {
  en: {
    translation: _merge(translationEN, SidenavItemsEN, advancedSearchEN),
  },
  fr: {
    translation: _merge(translationFR, SidenavItemsFR, advancedSearchFR),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng: defaultLanguage,
      supportedLngs: availableLangs,
      interpolation: { escapeValue: false },
      resources,
      lng: "en", // default lang
      keySeparator: ".", // to support nested translations
      detection: detectionOptions,
      debug: process.env.NODE_ENV === "development",
      lowerCaseLng: true,
    },
    (err, t) => {
      if (err) {
        console.error(err);
      }
    }
  );

export default i18n;
