import React, { CSSProperties, PropsWithChildren } from "react";

interface FilterBarProps {
  style?: CSSProperties;
}
export const FilterBar = ({ children, style }: PropsWithChildren<FilterBarProps>) => (
  <div className={"filter-bar"} style={{ ...style }}>
    {children}
  </div>
);

export const FilterWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className="filter-wrapper">{children}</div>
);

interface VerticalCollapseProps {
  toggle: boolean;
}
export const VerticalCollapse = ({
  children,
  toggle,
}: PropsWithChildren<VerticalCollapseProps>) => (
  <div className={`vertical-collapse ${toggle ? "show" : ""}`}>{children}</div>
);

export const ButtonWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className="button-wrapper">{children}</div>
);
