import React from "react";

export const FF_USAGE_DATA = "USAGE_DATA";
export const FF_DISTRIBUTION_ROYALTIES = "DISTRIBUTION_ROYALTIES";
export const FF_MARKET_SHARE = "MARKET_SHARE";
export const FF_ADVANCED_SEARCH = "ADVANCED_SEARCH";

// TODO: Check why the linter is not detecting the vars properly
export enum FeatureStatus {
  // eslint-disable-next-line no-unused-vars
  BETA = "beta",
  // eslint-disable-next-line no-unused-vars
  ALPHA = "alpha",
}

export function getFeatureFlag(
  conf: Record<string, any> | undefined,
  featureName?: string
): string | undefined {
  if (conf == null || featureName == null) return;
  if (conf[featureName]?.flag) return conf[featureName]?.flag;
}

export function isEnabledService(
  conf: Record<string, any> | undefined,
  featureName?: string
): boolean {

  if (!conf || Object.keys(conf).length === 0 || !featureName) {
    return false
  }
  if (conf[featureName]) {
    return conf[featureName].enabled
  }
  return false
}

export const ConfigurationContext = React.createContext<Record<
  string,
  any
> | undefined>(undefined)
