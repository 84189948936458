import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import typicalProgramStore, {
  initialState as initialStateTypicalPrograms,
} from "../slices/setListsSlice";
import worksStore, { initialState as initialStateWorks } from "../slices/worksSlice";
import draftsStore, { initialState as initialStateDraft } from "../slices/draftsSlice";
import appContextSlice, { initialState as initialStateAppContext } from "../slices/appContextSlice";
import representationsSlice, {
  initialState as initialStateRepresentation,
} from "../slices/representationsSlice";
import thunk from "redux-thunk";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import usageDataSlice, { initialState as usageDataInitialState } from "../slices/usageDataSlice";
import distributionRoyaltiesSlice, {
  initialState as distributionRoyaltiesState,
} from "../slices/distributionsRoyaltiesSlice";
import marketShareSlice, { initialState as marketShareState } from "../slices/marketShareSlice";

import advancedSearchSlice, {
  initialState as advancedSearchState,
} from "../slices/advancedSearchSlice";

const persistConfig = {
  key: "root",
  storage,
};

const appContextPersistConfig: PersistConfig<any> = {
  key: "appContext",
  storage: storageSession,
  whitelist: ["selectedSisterSociety", "switchAccount", "profile", "sisterSocities"],
};
const usageDataSearchParamsPersistConfig: PersistConfig<any> = {
  key: "usageData",
  storage: storageSession,
  whitelist: ["usageDataSearchParams"],
};

const persistedReducer = combineReducers({
  appContext: persistReducer(appContextPersistConfig, appContextSlice),
  setLists: typicalProgramStore,
  drafts: draftsStore,
  works: worksStore,
  representations: representationsSlice,
  usageData: usageDataSlice,
  distributionRoyalties: distributionRoyaltiesSlice,
  marketShare: marketShareSlice,
  advancedSearch: advancedSearchSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "app/reset") {
    storage.removeItem("persist:root");
    state = {
      ...state,
      appContext: initialStateAppContext,
      setLists: initialStateTypicalPrograms,
      drafts: initialStateDraft,
      works: initialStateWorks,
      representations: initialStateRepresentation,
      usageData: usageDataInitialState,
      distributionRoyalties: distributionRoyaltiesState,
      marketShare: marketShareState,
      advancedSearch: advancedSearchState,
    };
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
