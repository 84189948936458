import * as qs from "qs";
import { httpCaller } from "./httpCaller";

export function invokeRemoteGet<T, U>(
  path: string,
  queryParams: U
): Promise<{ data: T | null; status: number }> {
  const params = qs.stringify(queryParams, {
    arrayFormat: "comma",
    indices: false,
    format: "RFC3986",
  });
  return new Promise<{ data: T | null; status: number }>((resolve, reject) => {
    httpCaller
      .get<T>(path, {
        params,
        paramsSerializer: (params) => {
          return params;
        },
      })
      .then((response) => {
        return response.status === 200 || response.status === 204
          ? resolve({
              data: response.status === 204 ? null : response.data,
              status: response.status,
            })
          : reject(response.status);
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  });
}

const MAX_TRIES = 30;

/**
 * Method used to poll for a DLAKE response
 * @param resolve the method used to resolve the parent promise. It will be resolved with the data received
 * @param reject  the method used to reject the parent promise. It will be rejected with the status code
 * @param asyncId the asyncId received in the initial request
 * @param type the type of the request received in the initial request
 * @param tryNumber the number of tries. If superior to MAX_TRIES, the reject method will be invoked with a 500 code. If it is -1 it will be ignored
 * @param timeout The timeout used between tries. Default to 2000
 */
function pollForResponse<T>(
  resolve: (value: { data: T | null } | PromiseLike<{ data: T | null }>) => void,
  reject: (reason?: any) => void,
  asyncParams: any,
  asyncPath: string,
  tryNumber: number,
  timeout: number
): void {
  if (tryNumber >= MAX_TRIES) {
    reject(500);
  }
  setTimeout(() => {
    httpCaller
      .get<T | null>(asyncPath, { params: asyncParams })
      .then((response) => {
        if (response.status === 202) {
          pollForResponse(
            resolve,
            reject,
            asyncParams,
            asyncPath,
            tryNumber === -1 ? tryNumber : ++tryNumber,
            timeout
          );
        } else if (response.status === 204 || response.status === 200) {
          resolve({ data: response.status === 204 ? null : response.data });
        } else {
          reject(response.status);
        }
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  }, timeout);
}

export function pollGet<T, U>(
  path: string,
  asyncPath: string,
  queryParams: U,
  timeout: number = 2000
): Promise<{ data: T | null }> {
  const params = qs.stringify(queryParams, {
    arrayFormat: "comma",
    indices: false,
    format: "RFC3986",
  });
  return new Promise<{ data: T | null }>((resolve, reject) => {
    httpCaller
      .get<{ async_requestid: string; type: string }>(path, {
        params,
        paramsSerializer: (params) => {
          return params;
        },
      })
      .then((response) => {
        if (response.status === 202) {
          // If the response code is 202, we can start the polling
          pollForResponse(resolve, reject, response.data, asyncPath, 0, timeout);
        } else if (response.status === 204) {
          resolve({ data: null });
        } else {
          reject(response.status);
        }
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  });
}

export function invokeRemoteGetPathParam<T, U>(path: string, id: U): Promise<{ data: T | null }> {
  return new Promise<{ data: T | null }>((resolve, reject) => {
    httpCaller
      .get<T>(`${path}/${id}`)
      .then((response) => {
        return response.status === 200 || response.status === 204
          ? resolve({ data: response.status === 204 ? null : response.data })
          : reject(response.status);
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  });
}

export function invokeRemotePost<T, U>(
  path: string,
  payload: U,
  queryParams
): Promise<{ data: T | null }> {
  const params = qs.stringify(queryParams, {
    arrayFormat: "comma",
    indices: false,
    format: "RFC3986",
  });
  return new Promise<{ data: T | null }>((resolve, reject) => {
    httpCaller
      .post<T>(path, payload, {
        params,
        paramsSerializer: (params) => {
          return params;
        },
      })
      .then((response) => {
        return response.status === 200 || response.status === 204
          ? resolve({ data: response.status === 204 ? null : response.data })
          : reject(response.status);
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  });
}

export function invokeRemoteDelete<T, U>(path: string, id: U): Promise<{ data: T | null }> {
  return new Promise<{ data: T | null }>((resolve, reject) => {
    httpCaller
      .delete<T>(`${path}/${id}`)
      .then((response) => {
        return response.status === 200 || response.status === 204
          ? resolve({ data: response.status === 204 ? null : response.data })
          : reject(response.status);
      })
      .catch((error) => {
        console.error("API error", error);
        return reject(error);
      });
  });
}
