import { Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "../../../store/hooks";
import { renderMarketShareBlock } from "../commonComponents/utiliRenderers";
import { t } from "i18next";
import StreamsPerTerritory from "./StreamsPerTerritory";
import BoxSectionComponent from "../../../components/boxSectionComponent";
import { statusMessages } from "../../../utils/statusMessages";
import "./license-kpi.scss";
import EffectiveRatePerTerritories from "./EffectiveRatePerTerritories";
import PricingVsRevenue from "./PricingVsDeclaredRevenue";

export const Separator = (text: string, marginTop: string = "0", marginBot: string = "0") => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        color: "#363981",
        margin: `${marginTop} 0 ${marginBot} 0`,
      }}
    >
      <div
        style={{
          paddingLeft: "20px",
          whiteSpace: "nowrap",
          fontSize: "20px",
          fontWeight: "500",
        }}
      >
        {text}
      </div>{" "}
      <span
        style={{
          width: "-webkit-fill-available",
          borderBottom: "1px solid rgb(54, 57, 129, 40%)",
          margin: "14px 0px 20px 1%",
        }}
      ></span>
    </div>
  );
};
const LicenseKpi = () => {
  const salesPer1000StreamsPerCommercialModelPerUsagePeriod = useAppSelector(
    (state) => state.marketShare.licenceKpisData.streamsPerCommercialModel
  );

  const salesPer1000StreamsPerCommercialModelPerUsagePeriodStatus = useAppSelector(
    (state) => state.marketShare.status.licenceKpis.streamsPerCommercialModel
  );
  const salesPer1000StreamsPerTerritories = useAppSelector(
    (state) => state.marketShare.licenceKpisData.streamsPerTerritory
  );

  const salesPer1000StreamsPerTerritoriesStatus = useAppSelector(
    (state) => state.marketShare.status.licenceKpis.streamsPerTerritory
  );
  const effectiveRatePerTerritories = useAppSelector(
    (state) => state.marketShare.licenceKpisData.effectiveRatePerTerritories
  );

  const effectiveRatePerTerritoriesStatus = useAppSelector(
    (state) => state.marketShare.status.licenceKpis.effectiveRatePerTerritories
  );
  const EffectiveRatePerCommercialModelPerUsagePeriod = useAppSelector(
    (state) => state.marketShare.licenceKpisData.effectiveRateByCommercialModelPerUsagePeriods
  );

  const EffectiveRatePerCommercialModelPerUsagePeriodStatus = useAppSelector(
    (state) => state.marketShare.status.licenceKpis.effectiveRateByCommercialModelPerUsagePeriods
  );

  const PricingVsRevenueData = useAppSelector(
    (state) => state.marketShare.licenceKpisData.pricingVsRevenue
  );

  const PricingVsRevenueStatus = useAppSelector(
    (state) => state.marketShare.status.licenceKpis.pricingVsRevenue
  );
  const graphQuarterInterval = useAppSelector(
    (state) => state.marketShare.lastSearchValues.date.marketKpi
  );

  return (
    <Container className="kpi-page-content">
      <Row>{Separator(t("license-kpi.sections.per-1000-streams.title"), "25px")}</Row>

      <Row style={{ marginTop: "25px" }}>
        <Col md={6}>
          {renderMarketShareBlock(
            [],
            salesPer1000StreamsPerCommercialModelPerUsagePeriod,
            salesPer1000StreamsPerCommercialModelPerUsagePeriodStatus,
            graphQuarterInterval,
            "currency",
            "license-kpi.sections.per-1000-streams.boxes.per-commercial-model",
            "lines"
          )}
        </Col>
        <Col md={6} style={{ height: "560px" }}>
          <BoxSectionComponent
            title={t("license-kpi.sections.per-1000-streams.boxes.per-territory.title")}
            subtitle={t("license-kpi.sections.per-1000-streams.boxes.per-territory.subtitle")}
            bodyContent={
              <StreamsPerTerritory
                allTerritories={salesPer1000StreamsPerTerritories.map(
                  (royalty: { amount: number; territory: { code: string; label: string } }) => {
                    return royalty.territory.code;
                  }
                )}
                streamsPerTerritory={salesPer1000StreamsPerTerritories.map(
                  (royalty: { amount: number; territory: { code: string; label: string } }) => {
                    return {
                      territory_alpha2_code: royalty.territory.code,
                      price: royalty.amount * 1000,
                    };
                  }
                )}
              />
            }
            status={{
              statusCode: salesPer1000StreamsPerTerritoriesStatus,
              defaultMessage: statusMessages.defaultMessage,
              noDataFoundMessage: statusMessages.noDataFoundMessage,
              unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
            }}
          />
        </Col>
      </Row>
      <Row>{Separator(t("license-kpi.sections.effective-rate.title"), "25px")}</Row>

      <Row style={{ marginTop: "25px" }}>
        <Col md={6}>
          {renderMarketShareBlock(
            [],
            EffectiveRatePerCommercialModelPerUsagePeriod,
            EffectiveRatePerCommercialModelPerUsagePeriodStatus,
            graphQuarterInterval,
            "percentage",
            "license-kpi.sections.effective-rate.boxes.per-commercial-model",
            "lines"
          )}
        </Col>
        <Col md={6} style={{ height: "560px" }}>
          <BoxSectionComponent
            title={t("license-kpi.sections.per-1000-streams.boxes.per-territory.title")}
            subtitle={t("license-kpi.sections.per-1000-streams.boxes.per-territory.subtitle")}
            bodyContent={
              <EffectiveRatePerTerritories
                allTerritories={effectiveRatePerTerritories.map((rate) => {
                  return rate.territory.code;
                })}
                effectiveRatePerTerritory={effectiveRatePerTerritories.map((rate) => {
                  return {
                    territory_alpha2_code: rate.territory.code,
                    amount: rate.amount * 100,
                  };
                })}
              />
            }
            status={{
              statusCode: effectiveRatePerTerritoriesStatus,
              defaultMessage: statusMessages.defaultMessage,
              noDataFoundMessage: statusMessages.noDataFoundMessage,
              unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
            }}
          />
        </Col>
      </Row>
      <Row>
        {Separator(t("license-kpi.sections.declared-revenue-publishing-pool.title"), "25px")}
      </Row>

      <Row style={{ marginTop: "25px", paddingBottom: "25px" }}>
        <Col style={{ height: "503px" }}>
          <BoxSectionComponent
            title={t("license-kpi.sections.declared-revenue-publishing-pool.boxes.evolution.title")}
            subtitle={t(
              "license-kpi.sections.declared-revenue-publishing-pool.boxes.evolution.subtitle"
            )}
            bodyContent={<PricingVsRevenue data={PricingVsRevenueData} />}
            status={{
              statusCode: PricingVsRevenueStatus,
              defaultMessage: statusMessages.defaultMessage,
              noDataFoundMessage: statusMessages.noDataFoundMessage,
              unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LicenseKpi;
