import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./detailsPerIDE12.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation } from "react-router";

import {
  fetchUsageDataStreamsPerDistributionsAsync,
  fetchUsageDataStreamsPerOffersAsync,
  getWorksPerIDE12Async,
  resetUsageDataDetailsState,
} from "../../../slices/usageDataSlice";
import { UsageDataStreamPerWorkDetails } from "../../../types";
import { ProgressBarItem } from "../../../components/progressbar";
import LineChartComponent from "../../../components/lineChart";
import { getXOptions, transformData } from "../../../components/worksRanking/worksRankingUtils";
import countries from "i18n-iso-countries";
import { getItemsToDisplay } from "../progressBarHelper";
// TODO ORION-344
import { UsageDataStreamsPerDistributionsStackedBarCard } from "./UDStreamsPerDistributionsStackedBarCard";
import { UsageDataStreamsPerOffersStackedBarCard } from "./UDStreamsPerOffersStackedBarCard";
import ResourceId from "./resourceId";
import BoxSectionComponent from "../../../components/boxSectionComponent";
import { setBreadcrumb } from "../../../slices/appContextSlice";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const DetailsPerIDE12 = () => {
  const location: any = useLocation();

  const societyId = useAppSelector(
    (state) => state.appContext.selectedSisterSociety.sisterSocietyId
  );
  const selectedRepertoire = useAppSelector((state) => state.appContext.selectedRepertoire);

  const worksPerIDE12Status = useAppSelector(
    (state) => state.usageData.status.details.worksPerIDE12
  );
  const worksPerIDE12 = useAppSelector((state) => state.usageData.details.worksPerIDE12);

  const streamsPerDistributions = useAppSelector(
    (state) => state.usageData.details.streamsPerDistributions
  );

  const streamsPerDistributionsStatus = useAppSelector(
    (state) => state.usageData.status.details.streamsPerDistributions
  );

  const streamsPerOffers = useAppSelector((state) => state.usageData.details.streamsPerOffers);

  const streamsPerOffersStatus = useAppSelector(
    (state) => state.usageData.status.details.streamsPerOffers
  );

  const streamPerOfferPerWorkIdStatus = useAppSelector(
    (state) => state.usageData.status.details.streamPerOfferPerWorkId
  );
  const streamPerOfferPerWorkId = useAppSelector(
    (state) => state.usageData.details.streamPerOfferPerWorkId
  );

  const streamPerMonthPerWorkIdStatus = useAppSelector(
    (state) => state.usageData.status.details.streamPerMonthPerWorkId
  );
  const streamPerMonthPerWorkId = useAppSelector(
    (state) => state.usageData.details.streamPerMonthPerWorkId
  );
  const mandatorId = useAppSelector((state) => state.appContext.mandator);

  const dspList = useAppSelector((state) => state.usageData.dspList);

  const sisterSocietyEntityData = useAppSelector((state) => state.appContext.selectedSisterSociety);
  const sisterSocietyEntityName = sisterSocietyEntityData?.name;

  const dispatch = useAppDispatch();

  const { i18n, t } = useTranslation();

  const defaultLabel = t("usage_data.details.block.statusMessage.default");
  const errorLabel = "usage_data.details.block.statusMessage.500";
  const noDataLabel = t("usage_data.details.block.statusMessage.204");

  function getBaseParams() {
    return {
      sisterSocietyEntityName,
      territories: countries.numericToAlpha2(location.state?.territoryCode),
      dsp: location.state?.dsp,
      startDate: location.state?.startDate,
      endDate: location.state?.endDate,
      ide12: location.state?.ide12,
      repertoireId: selectedRepertoire,
      societyId: societyId,
      mandatorId,
    };
  }
  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: t("breadcrumb.usage-data-details"),
        parent: t("breadcrumb.usage-data"),
        showHome: true,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(resetUsageDataDetailsState());
    dispatch(getWorksPerIDE12Async(getBaseParams()));
    dispatch(fetchUsageDataStreamsPerDistributionsAsync(getBaseParams()));
    dispatch(fetchUsageDataStreamsPerOffersAsync(getBaseParams()));
  }, [dispatch, location]);

  function getLabel<T>(code: T, list: { code: T; label: string }[]) {
    return list.find((item) => item.code === code)?.label || code;
  }

  const streamPerOfferPerWorkIdList = useMemo(
    () =>
      getItemsToDisplay(
        -1,
        (streamPerOfferPerWorkId || []).map((item) => {
          return {
            code: item.code,
            displayName: item.offerGroupName,
            value: item.streams,
            percentage: item.percentage,
          } as ProgressBarItem;
        }),
        t("usage_data.stream-per-offer.othergroup")
      ),
    [streamPerOfferPerWorkId]
  );

  return (
    <Container className="usage-data-body-container" fluid>
      <Container className="my-4 map-list-container">
        <Row className={"mb-4"}>
          <Col>
            <BoxSectionComponent
              title={`${location.state?.title} - ${location.state?.ide12}`}
              subtitle=""
              status={{
                statusCode: -3,
                defaultMessage: t("statusMessage.default"),
                noDataFoundMessage: t("statusMessage.204"),
                unexpectedErrorMessage: t("statusMessage.500"),
              }}
              bodyContent={<></>}
            />
          </Col>
        </Row>

        {/* TODO ORION-344 */}
        {
          <Row className={"mb-4"}>
            <Col xs={12} sm={6}>
              <BoxSectionComponent
                title={t("usage_data.details.stream.distributions")}
                subtitle=""
                status={{
                  statusCode: -3,
                  defaultMessage: t("statusMessage.default"),
                  noDataFoundMessage: t("statusMessage.204"),
                  unexpectedErrorMessage: t("statusMessage.500"),
                }}
                bodyContent={
                  <UsageDataStreamsPerDistributionsStackedBarCard
                    data={streamsPerDistributions}
                    status={streamsPerDistributionsStatus}
                  />
                }
              />
            </Col>
            <Col xs={12} sm={6}>
              <BoxSectionComponent
                title={t("usage_data.details.stream.offers")}
                subtitle=""
                status={{
                  statusCode: -3,
                  defaultMessage: t("statusMessage.default"),
                  noDataFoundMessage: t("statusMessage.204"),
                  unexpectedErrorMessage: t("statusMessage.500"),
                }}
                overflowY="hidden"
                bodyContent={
                  <UsageDataStreamsPerOffersStackedBarCard
                    data={streamsPerOffers}
                    status={streamsPerOffersStatus}
                  />
                }
              />
            </Col>
          </Row>
        }
        <Row className={"mb-4"}>
          <Col style={{ minHeight: "400px", maxHeight: "600px" }}>
            <BoxSectionComponent
              title={t("usage_data.details.resourceId.title")}
              subtitle={t("usage_data.stream-per-works.dsp-terr", {
                dspName: getLabel<string>(location.state?.dsp, dspList),
                territory: countries.getName(location.state?.territoryCode, i18n.language),
              })}
              status={{
                statusCode: -3,
                defaultMessage: t("statusMessage.default"),
                noDataFoundMessage: t("statusMessage.204"),
                unexpectedErrorMessage: t("statusMessage.500"),
              }}
              bodyContent={
                <ResourceId
                  worksPerIDE12Status={worksPerIDE12Status}
                  worksPerIDE12={worksPerIDE12}
                  streamPerOfferPerWorkIdStatus={streamPerOfferPerWorkIdStatus}
                  streamPerMonthPerWorkIdStatus={streamPerMonthPerWorkIdStatus}
                  streamPerOfferPerWorkIdList={streamPerOfferPerWorkIdList}
                  getBaseParams={getBaseParams}
                />
              }
            />
          </Col>
        </Row>
        <Row className="pb-4">
          <Col>
            <BoxSectionComponent
              title={t("usage_data.details.stream.title")}
              subtitle={t("usage_data.stream-per-works.dsp-terr", {
                dspName: getLabel<string>(location.state?.dsp, dspList),
                territory: countries.getName(location.state?.territoryCode, i18n.language),
              })}
              status={{
                statusCode: -3,
                defaultMessage: t("statusMessage.default"),
                noDataFoundMessage: t("statusMessage.204"),
                unexpectedErrorMessage: t("statusMessage.500"),
              }}
              bodyContent={
                <LineChartComponent<UsageDataStreamPerWorkDetails>
                  inputData={streamPerMonthPerWorkId?.items || []}
                  loadingStatus={streamPerMonthPerWorkIdStatus}
                  togglableStates={[]}
                  transformerFunction={transformData}
                  xOptions={getXOptions(getBaseParams().startDate, getBaseParams().endDate)}
                  yOptions={{ min: 0 }}
                  defaultMessage={defaultLabel}
                  noDataFoundMessage={noDataLabel}
                  unexpectedErrorMessage={errorLabel}
                  width={15}
                  height={5}
                />
              }
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default DetailsPerIDE12;
