// External libraries
import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import { BeatLoader } from "react-spinners";

// Components
import DataTableMantine from "../../components/dataTableMantine";
import CodesCellText from "./CodesCellDisplay";
import TextWithHoverOnOverflow from "./TextWithHoverOnOverflow";
import TerritoriesAndDspsCell from "./TerritoriesAndDspsCell";

// Hooks
import { useQueryClient } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useAdvancedSearchDeleteMutation } from "../../react-query-hooks/advancedSearchQueries";

// API
import { getDownloadLink } from "../../api/advancedSearchApi";

// Utilities
import { convertToMonthYear, formatDateToYYYYMM } from "../../utils/dateUtils";

// Types
import { AdvancedSearchExport, SearchModesBiMap } from "../../types/advancedSearchTypes";

// Redux actions
import {
  reseAdvancedSearchSearchParams,
  setSearchParamsDatesAction,
  setSearchParamsDspResourceCodesAction,
  setSearchParamsDspsAction,
  setSearchParamsIsrcAction,
  setSearchParamsIswcAction,
  setSearchParamsParticipantAction,
  setSearchParamsParticipantModeAction,
  setSearchParamsSacemWorkCodesAction,
  setSearchParamsTerritoriesAction,
  setSearchParamsTitleAction,
  setSearchParamsTitleModeAction,
  setSearchParamsVideoIdAction,
} from "../../slices/advancedSearchSlice";

// Styles
import "./index.scss";

const ExportsTable = ({
  exportsList,
  isLoading,
}: {
  exportsList: AdvancedSearchExport[];
  isLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const sisterSocietyId = useAppSelector(
    (state) => state.appContext.selectedSisterSociety.sisterSocietyId
  );
  const { isError: DeleteFailed, mutate: deleteExport } = useAdvancedSearchDeleteMutation(
    queryClient,
    sisterSocietyId
  );

  const handleGetDownloadUrl = async (exportId: string) => {
    try {
      const downloadUrl = await getDownloadLink(exportId);
      if (downloadUrl.data !== null) window.location.href = downloadUrl.data;
    } catch (error) {}
  };
  const handleEditExport = (row: AdvancedSearchExport) => {
    dispatch(reseAdvancedSearchSearchParams());
    dispatch(
      setSearchParamsDatesAction({
        dates: {
          startingDate: formatDateToYYYYMM(
            new Date(
              Number(row.start_date.substring(0, 4)),
              Number(row.start_date.substring(5, 7)) - 1
            )
          ),
          endingDate: formatDateToYYYYMM(
            new Date(Number(row.end_date.substring(0, 4)), Number(row.end_date.substring(5, 7)) - 1)
          ),
        },
      })
    );

    row.dsps && dispatch(setSearchParamsDspsAction(row.dsps));

    row.territories && dispatch(setSearchParamsTerritoriesAction(row.territories));

    row.title && dispatch(setSearchParamsTitleAction(row.title));

    row.title_filter &&
      dispatch(
        setSearchParamsTitleModeAction(SearchModesBiMap.getKey(row.title_filter) || "Include")
      );
    row.participant_filter &&
      dispatch(
        setSearchParamsParticipantModeAction(
          SearchModesBiMap.getKey(row.participant_filter) || "Include"
        )
      );
    row.isrc && dispatch(setSearchParamsIsrcAction(row.isrc));

    row.iswc && dispatch(setSearchParamsIswcAction(row.iswc.substring(1)));
    row.sacem_work_codes && dispatch(setSearchParamsSacemWorkCodesAction(row.sacem_work_codes));
    row.dsp_work_codes && dispatch(setSearchParamsDspResourceCodesAction(row.dsp_work_codes));
    row.video_id && dispatch(setSearchParamsVideoIdAction(row.video_id));

    row.participant && dispatch(setSearchParamsParticipantAction(row.participant));
  };
  const priodCell = ({ cell }: { cell: any }) => {
    const row: AdvancedSearchExport = cell.row.original;

    return (
      <>
        {convertToMonthYear(row.start_date)} {" - "}
        {convertToMonthYear(row.end_date)}
      </>
    );
  };
  const territoriesCell = ({ cell }: { cell: any }) => {
    const value: { display_label: string; alpha2_label: string }[] = cell.getValue();
    const row: AdvancedSearchExport = cell.row.original;
    if (value === null || value.length === 0) return "ALL";

    return (
      <TerritoriesAndDspsCell
        key={row.export_id}
        codesList={value.map((terr) => terr.display_label)}
      />
    );
  };
  const dspsCell = ({ cell }: { cell: any }) => {
    const value: string[] = cell.getValue();
    const row: AdvancedSearchExport = cell.row.original;

    if (value === null || value.length === 0) return "ALL";

    return <TerritoriesAndDspsCell key={row.export_id} codesList={value} />;
  };
  const printValueAsIsCell = ({ cell }: { cell: any }) => {
    const value = cell.getValue();
    const row: AdvancedSearchExport = cell.row.original;

    return <TextWithHoverOnOverflow text={value} key={row.export_id + "1"} />;
  };
  const CodesCell = ({ cell }: { cell: any }) => {
    const row: AdvancedSearchExport = cell.row.original;
    const iswc = row.iswc;
    const isrc = row.isrc;
    const videoId = row.video_id;
    const sacemWorkCodes = row.sacem_work_codes;
    const dspWorkCodes = row.dsp_work_codes;

    const key = row.export_id;
    let NewCodes: { [key: string]: string | string[] } = {};
    if (iswc && iswc !== "None") {
      NewCodes["ISWC"] = iswc;
    }
    if (isrc && isrc !== "None") {
      NewCodes["ISRC"] = isrc;
    }
    if (videoId && videoId !== "None") {
      NewCodes["Video Id"] = videoId;
    }
    if (sacemWorkCodes && sacemWorkCodes.length > 0) {
      NewCodes["Sacem Work Codes"] = sacemWorkCodes;
    }
    if (dspWorkCodes && dspWorkCodes.length > 0) {
      NewCodes["DSP Resource Codes"] = dspWorkCodes;
    }

    return <CodesCellText codes={NewCodes} key={key} />;
  };
  const toolSectionCell = ({ cell }: { cell: any }) => {
    const row: AdvancedSearchExport = cell.row.original;
    const value = cell.getValue();
    if (value === -1) {
      return (
        <div className="export-loading-box">
          <BeatLoader size={8} />
          <span style={{ marginLeft: "10px", color: "#563D7C" }}>
            {t<string>("advanced-search.table.loading")}
          </span>
        </div>
      );
    }

    return (
      <div className="status-tools-section">
        {value === 200 ? (
          <div
            title="Download"
            className="download-button"
            onClick={() => handleGetDownloadUrl(row.export_id)}
          />
        ) : value === 600 ? (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="button-tooltip">
                {t<string>(`advanced-search.table.status.${value}`)}
              </Tooltip>
            }
          >
            <div className="too-many-rows-button" />
          </OverlayTrigger>
        ) : value === 204 ? (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="button-tooltip">
                {t<string>(`advanced-search.table.status.${value}`)}
              </Tooltip>
            }
          >
            <div className="no-rows-button" />
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="button-tooltip">
                {t<string>(`advanced-search.table.status.${value}`)}
              </Tooltip>
            }
          >
            <div className="failed-download" />
          </OverlayTrigger>
        )}
        <div
          title="Edit"
          className="edit-button"
          onClick={() => {
            handleEditExport(row);
          }}
        />
        <div title="Delete" className="delete-button" onClick={() => deleteExport(row.export_id)} />
      </div>
    );
  };
  const constructMantineColumns = () => {
    return [
      {
        accessorKey: "start_date",
        header: "Period",
        Cell: priodCell,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "territories",
        header: "Territories",
        Cell: territoriesCell,

        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "dsps",
        header: "DSP",
        Cell: dspsCell,

        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "participant",
        header: "Participant",
        Cell: printValueAsIsCell,

        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: printValueAsIsCell,

        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "iswc",
        header: "Codes",
        Cell: CodesCell,

        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "creation_date",
        header: "Date",
        Cell: printValueAsIsCell,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
      {
        accessorKey: "status",
        header: "Actions",
        Cell: toolSectionCell,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 90,
      },
    ];
  };
  return (
    <DataTableMantine<AdvancedSearchExport>
      columns={constructMantineColumns()}
      data={exportsList}
      isLoading={isLoading}
      tableProps={{
        enableStickyHeader: true,
        enableDensityToggle: false,
        mantineSearchTextInputProps: { sx: { display: "none" } },
      }}
    />
  );
};

export default ExportsTable;
