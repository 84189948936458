import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const UrightsBox = (props : Props) => {
	const { buttonLabel, redirectionLink, className, external } = props;

	return (
		<div className='urights-box col-4 d-flex justify-content-between'>
			<div className={`box ${className}`}>
				{external ? 
					<a href={redirectionLink} target="_blank" rel="noopener noreferrer">
						{buttonLabel}
					</a>
					:
					<Link to={{ pathname: redirectionLink }} className='box-navigate'>
						{buttonLabel}
					</Link>
				}
			</div>
		</div>
	);
};
export default UrightsBox;

interface Props {
	buttonLabel: string
	redirectionLink?: string
	className: string
	external?: boolean
}


