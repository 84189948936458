import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { formatDateToYYYYMM, getOffsetDate } from "../utils/dateUtils";
import { getDspsMarketShare, getTerritoriesMarketShare } from "../api/marketShareDataApi";
import { AdvancedSearchState, SearchModes } from "../types/advancedSearchTypes";

export const initialState: AdvancedSearchState = {
  searchParams: {
    dates: {
      startingDate: formatDateToYYYYMM(getOffsetDate(0, 3)),
      endingDate: formatDateToYYYYMM(getOffsetDate(0, 1, 0)),
    },
    dsps: [],
    territories: [],
    isrcCode: "",
    iswcCode: "",
    dspResourceCodes: [],
    sacemWorkCodes: [],
    videoId: "",
    publisherCode: "",
    titleMode: "Include",
    title: "",
    participant: "",
    participantMode: "Include",
  },
};

export const fetchTerritoriesAsync = createAsyncThunk(
  "advanced-search/territories/fetch",
  async (params: { mandatorId: number }, { rejectWithValue }) => {
    try {
      return await getTerritoriesMarketShare(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchDspsAsync = createAsyncThunk(
  "advanced-search/dsps/fetch",
  async (params: { mandatorId: number; referential: string }, { rejectWithValue }) => {
    try {
      return await getDspsMarketShare(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadAdvancedSearchLastSearchFilters = createAsyncThunk(
  "marketShare/searchParams/load",
  async () => {
    return;
  }
);

export const advancedSearchSlice = createSlice({
  name: "advancedSearch",
  initialState,
  reducers: {
    resetAdvancedSearchState: (state: AdvancedSearchState) => {
      return initialState;
    },

    reseAdvancedSearchSearchParams: (state: AdvancedSearchState) => {
      state.searchParams = initialState.searchParams;
    },
    setSearchParamsTerritoriesAction: (
      state: AdvancedSearchState,
      action: PayloadAction<{ display_label: string; alpha2_label: string }[]>
    ) => {
      state.searchParams.territories = action.payload;
    },
    setSearchParamsDspsAction: (state: AdvancedSearchState, action: PayloadAction<string[]>) => {
      state.searchParams.dsps = action.payload;
    },
    setSearchParamsDatesAction: (
      state: AdvancedSearchState,
      action: PayloadAction<{ dates: { startingDate: string; endingDate: string } }>
    ) => {
      state.searchParams.dates = action.payload.dates;
    },
    setSearchParamsTitleAction: (state: AdvancedSearchState, action: PayloadAction<string>) => {
      state.searchParams.title = action.payload;
    },
    setSearchParamsTitleModeAction: (
      state: AdvancedSearchState,
      action: PayloadAction<SearchModes>
    ) => {
      state.searchParams.titleMode = action.payload;
    },
    setSearchParamsParticipantModeAction: (
      state: AdvancedSearchState,
      action: PayloadAction<SearchModes>
    ) => {
      state.searchParams.participantMode = action.payload;
    },
    setSearchParamsSacemWorkCodesAction: (
      state: AdvancedSearchState,
      action: PayloadAction<string[]>
    ) => {
      state.searchParams.sacemWorkCodes = action.payload;
    },
    setSearchParamsDspResourceCodesAction: (
      state: AdvancedSearchState,
      action: PayloadAction<string[]>
    ) => {
      state.searchParams.dspResourceCodes = action.payload;
    },
    setSearchParamsVideoIdAction: (state: AdvancedSearchState, action: PayloadAction<string>) => {
      state.searchParams.videoId = action.payload;
    },
    setSearchParamsIsrcAction: (state: AdvancedSearchState, action: PayloadAction<string>) => {
      state.searchParams.isrcCode = action.payload;
    },
    setSearchParamsIswcAction: (state: AdvancedSearchState, action: PayloadAction<string>) => {
      state.searchParams.iswcCode = action.payload;
    },
    setSearchParamsParticipantAction: (
      state: AdvancedSearchState,
      action: PayloadAction<string>
    ) => {
      state.searchParams.participant = action.payload;
    },
  },
});
export const {
  reseAdvancedSearchSearchParams,
  setSearchParamsTerritoriesAction,
  setSearchParamsDspsAction,
  setSearchParamsDatesAction,
  setSearchParamsTitleAction,
  setSearchParamsTitleModeAction,
  setSearchParamsSacemWorkCodesAction,
  setSearchParamsParticipantModeAction,
  setSearchParamsVideoIdAction,
  setSearchParamsDspResourceCodesAction,
  setSearchParamsIsrcAction,
  setSearchParamsIswcAction,
  setSearchParamsParticipantAction,
} = advancedSearchSlice.actions;
export default advancedSearchSlice.reducer;
