import React, { useEffect, useState } from "react";
import { CloseButton, Loader, MultiSelect, SelectItem } from "@mantine/core";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./filtersComponent.scss";

const MultiSelectComponent = (filterProps: MultiSelectProps) => {
  const { t } = useTranslation();
  const [multiSelValues, setMultiSelValues] = useState<string[]>([]);
  const [sortedData, setSortedData] = useState<string[] | SelectItem[]>([]);
  useEffect(() => {
    if (filterProps.data.length > 0) {
      const d = filterProps.data.sort((item1: SelectItem, item2: SelectItem) => {
        return item1.label && item2.label ? item1.label.localeCompare(item2.label) : 1;
      });
      setSortedData(d);
    }
    if (filterProps.data.length > 0) {
      const selectedItems = filterProps.data
        .filter((item: any) => item.code && filterProps.selectedValues.indexOf(item.code) > -1)
        .map((d: any) => d.label);

      setMultiSelValues(selectedItems);
    }
  }, [filterProps.data, filterProps.selectedValues]);

  const handleOnChange = (selectedFilters: string[]) => {
    setMultiSelValues(selectedFilters);
    const selectedItems = filterProps.data.filter(
      (data: any) => selectedFilters.indexOf(data.label) > -1
    );
    if (filterProps.onSelected) {
      filterProps.onSelected(selectedItems);
    }
  };

  return (
    <MultiSelect
      data={sortedData}
      value={multiSelValues}
      onChange={handleOnChange}
      creatable
      placeholder={filterProps.textPlaceholder}
      rightSection={
        filterProps.status === -1 ? (
          <Loader size="1.5rem" color={"#363981"} />
        ) : multiSelValues.length === 0 ? (
          <span className={"filter-arrow-down cursor-pointer"} />
        ) : (
          <div className={"d-flex pe-4"}>
            {multiSelValues.length > 2 ? (
              <OverlayTrigger
                placement={"right"}
                overlay={
                  <Tooltip id="button-tooltip">
                    {multiSelValues.map((val, index) => {
                      return (
                        <div className={"text-start px-2 text-nowrap"} key={`${val}-${index}`}>
                          {val}
                        </div>
                      );
                    })}
                  </Tooltip>
                }
              >
                <Badge
                  bg={"secondary"}
                  pill
                  className="badge-icon float-start"
                  text={"light"}
                  style={{ background: "#363981 !important" }}
                >
                  {multiSelValues.length}
                </Badge>
              </OverlayTrigger>
            ) : (
              <Badge
                bg={"secondary"}
                pill
                className="badge-icon float-start"
                text={"light"}
                style={{ background: "#363981 !important" }}
              >
                {multiSelValues.length}
              </Badge>
            )}
            <CloseButton onClick={() => handleOnChange([])} className={"float-start"} />
          </div>
        )
      }
      styles={{
        rightSection: {
          pointerEvents: multiSelValues.length === 0 ? "none" : "auto",
        },
        defaultValue: {
          maxWidth: "80px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipse",
        },
      }}
      maxDropdownHeight={190}
      searchable={true}
      disableSelectedItemFiltering
      nothingFound={t("search-data.no-data")}
      disabled={filterProps.searchIsLoading || filterProps.disabled}
      style={
        filterProps.widthFilter !== null ? { width: filterProps.widthFilter } : { width: "280px" }
      }
    />
  );
};

interface MultiSelectProps {
  searchIsLoading?: boolean;
  titleFieldset?: string;
  textPlaceholder?: string;
  widthFilter?: string;
  data: SelectItem[];
  onSelected?: Function;
  status?: number;
  selectedValues: string[];
  disabled?: boolean;
}

export default MultiSelectComponent;
