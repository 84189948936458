import { BiMap } from "../utils/customDataStructures";

export interface PostAdvancedSearchRequestPayload {
  societyId: string | undefined;
  start_period: string | null;
  end_period: string | null;
  title?: string;
  title_filter?: string;
  dsps?: string[];
  territories?: { display_label: string; alpha2_label: string }[];
  participant?: string;
  participant_filter?: string;
  iswc?: string;
  isrc?: string;
  sacem_work_codes?: string[];
  dsp_resource_codes?: string[];
  video_id?: string;
}

export interface AdvancedSearchExport {
  export_id: string;
  status: number;
  start_date: string;
  end_date: string;
  territories?: { display_label: string; alpha2_label: string }[];
  dsps?: string[];
  participant?: string;
  iswc?: string;
  isrc?: string;
  video_id?: string;
  dsp_work_codes?: string[];
  sacem_work_codes?: string[];
  title?: string;
  title_filter?: DlakeSearchModes;
  participant_filter?: DlakeSearchModes;
  publisher_code?: string;
}
export type SearchModes = "Include" | "Start" | "Exact";
export type DlakeSearchModes = "CONTAINS" | "START" | "EXACT";
export interface AdvancedSearchState {
  searchParams: {
    dates: {
      startingDate: string;
      endingDate: string;
    };
    dsps: string[];
    territories: { display_label: string; alpha2_label: string }[];
    isrcCode: string;
    iswcCode: string;
    dspResourceCodes: string[];
    sacemWorkCodes: string[];
    publisherCode: string;
    videoId: string;
    titleMode: SearchModes;
    title: string;
    participantMode: SearchModes;
    participant: string;
  };
}
export const SearchModesBiMap = new BiMap<SearchModes, DlakeSearchModes>();
SearchModesBiMap.set("Include", "CONTAINS");
SearchModesBiMap.set("Start", "START");
SearchModesBiMap.set("Exact", "EXACT");
export interface AdvancedSearchLicenses {
  dsp: {
    dspId: string;
    label: string;
    territories: { territoryId: string; label: string }[];
  }[];
  territory: {
    territoryId: string;
    label: string;
    dsps: { dspId: string; label: string }[];
  }[];
}
