import { ChartDataset, TooltipItem } from "chart.js";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { offerBarColors } from "../../../components/stackedBarChart/barColors";

import { StringDateRange } from "../../../types/marketShareTypes";
import { ExploitationPeriod } from "../../../types/distributionsRoyaltiesTypes";
import { formatCurrencyNumber, formatNumber } from "../../../helpers/formatter";

import { StackedBarChart } from "../../../components/stackedBarChart";
import { getCurrencyYOptions, getYOptions } from "../../../helpers/chartHelper";
import moment from "moment";

const KpiStackedBarsComponentBox = ({
  data,
  dataType,
  i18marker,
  quarterInterval,
}: {
  data: ExploitationPeriod[];
  dataType: "int" | "currency" | "percentage";
  i18marker: string;
  quarterInterval: StringDateRange;
}) => {
  const { t, i18n } = useTranslation();

  const formatYAxisLabel = (label: number) => {
    switch (dataType) {
      case "int":
        return formatNumber(i18n.language, label, false);
      case "currency":
        return formatCurrencyNumber(i18n.language, label);
      case "percentage":
        return formatNumber(i18n.language, label) + "%";
    }
  };
  const tooltipFormatNumbersCallback = () => {
    return {
      title: function (context: TooltipItem<"bar">[]) {
        const { raw } = context[0] as {
          raw: {
            period: string;
            percentage: number;
            amount: number;
            code: string;
          };
        };
        return raw.period;
      },
      label: function (context: TooltipItem<"bar">) {
        const {
          dataset: { label, data },
          dataIndex,
        } = context;
        const { percentage } = data[dataIndex] as any;

        return label + ": " + formatNumber(i18n.language, percentage) + "%";
      },
      footer: function (context: TooltipItem<"bar">[]) {
        const {
          parsed: { y },
        } = context[0];
        let label = `${t(`${i18marker}.stacked-bar-hover`)}: `;
        if (y) {
          label += formatYAxisLabel(y);
        }
        return label;
      },
    };
  };

  const chartData: {
    labels: string[];
    datasets: ChartDataset<
      "bar",
      { period: string; percentage: number; amount: number; code: string }[]
    >[];
  } = useMemo(() => {
    const labels = data.map((exploitationPeriod) => exploitationPeriod.period);
    const mappedChartData: ChartDataset<
      "bar",
      { period: string; percentage: number; amount: number; code: string }[]
    >[] = [];
    data.forEach((exploitationPeriod) => {
      const { period, items } = exploitationPeriod;

      items.forEach((item) => {
        const {
          amount: { value, percentage },
          label,
          code,
        } = item;
        const index = mappedChartData.findIndex((data) => data.label === label);
        if (index >= 0) {
          mappedChartData[index].data.push({
            period: period,
            percentage: percentage,
            amount: value,
            code: code,
          });
        } else {
          mappedChartData.push({
            label: label,
            data: [
              {
                period: period,
                percentage: percentage,
                amount: value,
                code: code,
              },
            ],
            borderRadius: 4,
            hidden: false,
            backgroundColor: offerBarColors[code],
            parsing: {
              xAxisKey: "period",
              yAxisKey: "amount",
            },
          });
        }
      });
    });
    return { labels: labels, datasets: mappedChartData };
  }, [data]);

  const getContextualizedYOptions = () => {
    switch (dataType) {
      case "int":
        return getYOptions({
          autoSkip: false,
          beginAtZero: true,
        });
      case "currency":
        return getCurrencyYOptions({
          autoSkip: false,
          beginAtZero: true,
        });
    }
  };

  return (
    <StackedBarChart<{
      period: string;
      percentage: number;
      amount: number;
      code: string;
    }>
      labels={chartData.labels}
      datasets={chartData.datasets}
      xOptions={getXXOptions(quarterInterval.startingDate, quarterInterval.endingDate, "en")}
      yOptions={getContextualizedYOptions()}
      lang={i18n.language}
      stackedBarTooltipCallbacks={tooltipFormatNumbersCallback()}
      height="380px"
    />
  );
};

function getXXOptions(startDate: string, endDate: string, lang: "fr" | "en" = "en") {
  moment.locale(lang);
  const DATE_FORMAT = "YYYYMM";

  return {
    stacked: true,
    type: "time",
    min:
      moment(startDate, DATE_FORMAT).year() + moment(startDate, DATE_FORMAT).quarter().toString(),
    max: moment(endDate, DATE_FORMAT).year() + moment(endDate, DATE_FORMAT).quarter().toString(),
    time: {
      parser: "YYYYQ",
      unit: "quarter",
    },
    ticks: {
      autoSkip: false,
      maxRotation: 35,
      minRotation: 35,
      callback: function (value, index, ticks) {
        const momentValue = moment(ticks[index].value);
        const year = momentValue.year();
        const quarter = momentValue.quarter();
        return `${year}Q${quarter}`;
      },
    },
  };
}
export default KpiStackedBarsComponentBox;
