import { t } from "i18next";
import BoxSectionComponent from "../../../components/boxSectionComponent";
import { ExploitationPeriod } from "../../../types/distributionsRoyaltiesTypes";
import { LineChartInputData, StringDateRange } from "../../../types/marketShareTypes";
import { statusMessages } from "../../../utils/statusMessages";
import KpiLineChartComponentBox from "./KpiLineChartsComponent";
import KpiStackedBarsComponentBox from "./KpiStackedBarsComponent";

const renderMarketShareGraph = (
  barData: ExploitationPeriod[],
  lineData: LineChartInputData[],
  status: number,
  graphQuarterInterval: StringDateRange,
  dataType: "currency" | "int" | "percentage",
  i18nMarker: string,
  dataFormStyle: "bars" | "lines"
) => {
  return dataFormStyle === "bars" ? (
    <KpiStackedBarsComponentBox
      data={barData}
      dataType={dataType}
      i18marker={i18nMarker}
      quarterInterval={graphQuarterInterval}
    />
  ) : (
    <KpiLineChartComponentBox
      quarterInterval={graphQuarterInterval}
      lineChartData={lineData}
      lineChartStatus={status}
      dataFormat={dataType}
      i18marker={i18nMarker}
    />
  );
};

export const renderMarketShareBlock = (
  barData: ExploitationPeriod[],
  lineData: LineChartInputData[],
  status: number,
  graphQuarterInterval: StringDateRange,
  dataType: "currency" | "int" | "percentage",
  i18nMarker: string,
  dataFormStyle: "bars" | "lines"
) => {
  return (
    <div style={{ height: "560px" }}>
      <BoxSectionComponent
        title={t(i18nMarker + ".title")}
        subtitle={t(i18nMarker + ".subtitle")}
        bodyContent={renderMarketShareGraph(
          barData,
          lineData,
          status,
          graphQuarterInterval,
          dataType,
          i18nMarker,
          dataFormStyle
        )}
        status={{
          statusCode: status,
          defaultMessage: statusMessages.defaultMessage,
          noDataFoundMessage: statusMessages.noDataFoundMessage,
          unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
        }}
      />
    </div>
  );
};
