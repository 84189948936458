import React from "react";

const ShowMoreFilterBtn = (btnProps: ButtonProps) => {
  return (
    <button className={btnProps.cssClassName + " show-more-btn"} onClick={btnProps.onClickHandler}>
      <span className={btnProps.cssArrowDirection}>{btnProps.text}</span>
    </button>
  );
};

export default ShowMoreFilterBtn;

interface ButtonProps {
  cssClassName?: string;
  cssArrowDirection: "arrow-down" | "arrow-up";
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
}
