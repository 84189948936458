import React, { forwardRef, useEffect, useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "./filtersComponent.scss";

const DateMonthRangeComponent = (dateRangeProps: DateRangeProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [minDate, setMinDate] = useState<Date>(dateRangeProps.minDate!);
  const [maxDate, setMaxDate] = useState<Date>(dateRangeProps.maxDate!);

  const parseDate = (date: string | undefined) => {
    if (date !== null && date !== "") {
      const parsedDate = moment(date, "YYYYMMDD");
      if (parsedDate.isValid()) {
        return parsedDate.toDate();
      }
    }
    return null;
  };

  const setDefaultDateRange = useCallback((): [Date | null, Date | null] => {
    let defaultDateRange: [Date | null, Date | null] = [null, null];
    if (dateRangeProps.startDate && dateRangeProps.endDate) {
      defaultDateRange = [parseDate(dateRangeProps.startDate), parseDate(dateRangeProps.endDate)];
    } else {
      defaultDateRange = [
        moment().subtract(3, "months").toDate(),
        moment().subtract(1, "months").toDate(),
      ];
    }

    setDateRange(defaultDateRange);
    return defaultDateRange;
  }, [dateRangeProps.startDate, dateRangeProps.endDate]);

  useEffect(() => {
    setDefaultDateRange();
  }, [setDefaultDateRange]);

  // eslint-disable-next-line react/display-name
  const ExampleCustomInput = forwardRef((props: propTypes, ref: any) => (
    <button
      className="date-month-range"
      onClick={props.onClick}
      ref={ref}
      style={{
        height: "36px",
        width: dateRangeProps.widthFilter !== null ? dateRangeProps.widthFilter : "280px",
      }}
      disabled={dateRangeProps.searchIsLoading}
    >
      <span>{props.value}</span>
      {!props.value && <span className={"filter-arrow-down month-arrow-down"} />}
    </button>
  ));

  const monthHandleChange = (newDateRange: [Date | null, Date | null]) => {
    if (!dateRangeProps.maxIntervalInMonths) setDateRange(newDateRange);

    if (newDateRange[0] && !newDateRange[1]) {
      let newMaxDate = new Date(newDateRange[0]);
      let newMinDate = new Date(newDateRange[0]);

      newMaxDate.setMonth(newDateRange[0]?.getMonth() + 12);

      newMinDate.setMonth(newDateRange[0]?.getMonth() - 12);

      newMinDate = dateRangeProps.minDate! < newMinDate ? newMinDate : dateRangeProps.minDate!;
      newMaxDate = newMaxDate < dateRangeProps.maxDate! ? newMaxDate : dateRangeProps.maxDate!;

      setMaxDate(newMaxDate);
      setMinDate(newMinDate);
      setDateRange(newDateRange);
    }
  };

  const updateDateRange = () => {
    setMaxDate(dateRangeProps.maxDate!);
    setMinDate(dateRangeProps.minDate!);

    if (dateRange.filter((elem) => elem == null).length > 0) {
      handleChangeParentComponent(setDefaultDateRange());
    } else {
      handleChangeParentComponent(dateRange);
    }
  };

  const handleChangeParentComponent = (defaultDateRange: [Date | null, Date | null]) => {
    if (
      dateRangeProps.handleComponentChange &&
      defaultDateRange[0] != null &&
      defaultDateRange[1] != null
    ) {
      dateRangeProps.handleComponentChange({
        startingDate: moment(defaultDateRange[0]).format("YYYYMM"),
        endingDate: moment(defaultDateRange[1]).format("YYYYMM"),
      });
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={monthHandleChange}
      onCalendarClose={updateDateRange}
      selectsRange
      showPreviousMonths
      startDate={startDate}
      endDate={endDate}
      dateFormat="yyyy, MM"
      showMonthYearPicker
      customInput={<ExampleCustomInput onClick={onclick} value={""} />}
      maxDate={maxDate}
      minDate={minDate}
      disabled={dateRangeProps.searchIsLoading}
    />
  );
};

interface propTypes {
  value: any;
  onClick: any;
}
interface DateRangeProps {
  searchIsLoading?: boolean;
  textPlaceholder?: string;
  widthFilter?: string;
  handleComponentChange?: Function;
  maxDate?: Date;
  minDate?: Date;
  startDate?: string;
  endDate?: string;
  dateFormat?: string;
  maxIntervalInMonths?: number;
}
export default DateMonthRangeComponent;
