import React, { useEffect, useRef, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import TextWithHoverOnOverflow from "./TextWithHoverOnOverflow";
const TerritoriesAndDspsCell = ({ codesList }: { codesList: string[] }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);

  useEffect(() => {
    const container = containerRef.current;

    if (container && codesList.length > 1) {
      let totalWidth = 0;
      let newVisibleItems: string[] = [];
      let newHiddenItems: string[] = [];

      const containerWidth = container.clientWidth;
      const badgeWidth = 40;
      const availableWidth = containerWidth;
      const availableWidthWithBadgeAndEllipsis = containerWidth - badgeWidth;

      for (const [index, code] of codesList.entries()) {
        const itemSpan = document.createElement("span");
        itemSpan.style.whiteSpace = "nowrap";
        itemSpan.style.visibility = "hidden";
        itemSpan.textContent = index < codesList.length - 1 ? `${code}, ` : code;
        document.body.appendChild(itemSpan);

        const itemWidth = itemSpan.offsetWidth;
        document.body.removeChild(itemSpan);

        if (totalWidth + itemWidth <= availableWidth) {
          if (totalWidth + itemWidth > availableWidthWithBadgeAndEllipsis) {
            newHiddenItems = codesList.slice(index);
            break;
          }

          totalWidth += itemWidth;
          newVisibleItems.push(code);
        } else {
          newHiddenItems = codesList.slice(index);

          break;
        }
      }

      setVisibleItems(newVisibleItems);
      setHiddenItems(newHiddenItems);
    } else {
      setVisibleItems(codesList);
      setHiddenItems([]);
    }
  }, [codesList]);

  const hiddenItemsCount = hiddenItems.length;
  const tooltipContent = hiddenItems.map((item, index) => (
    <div key={item + index} style={{ textAlign: "left", width: "fit-content" }}>
      {item}
    </div>
  ));

  return (
    <>
      {codesList.length === 1 ? (
        <TextWithHoverOnOverflow text={codesList[0]} />
      ) : (
        <div
          ref={containerRef}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {visibleItems.join(", ")}
          </div>

          {hiddenItemsCount > 0 && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">{tooltipContent}</Tooltip>}
            >
              <Badge
                pill
                className="table-pill"
                style={{
                  width: "30px",
                  height: "20px",
                  marginLeft: "8px",
                }}
              >
                + {hiddenItemsCount}
              </Badge>
            </OverlayTrigger>
          )}
        </div>
      )}
    </>
  );
};

export default TerritoriesAndDspsCell;
