import React, { useEffect, useState } from "react";
import { CloseButton, MultiSelect, SelectItem } from "@mantine/core";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

const reformatQueryString = (query: string, prefix?: string, upperCaseText?: boolean) => {
  const element = prefix ? prefix + query : query;
  return upperCaseText ? element.toUpperCase() : element;
};

const CreatableMultiSelectComponent = (filterProps: FilterProps) => {
  const [data, setData] = useState<{ value: string; label: string }[]>([]);
  const [multiSelValues, setMultiSelValues] = useState<string[]>([]);
  useEffect(() => {
    if (filterProps.data) {
      setData(
        filterProps.selectedValues
          ? filterProps.selectedValues.map((val) => {
              return {
                value: reformatQueryString(val, "", filterProps.upperCaseText),
                label: reformatQueryString(val, filterProps.prefix, filterProps.upperCaseText),
              };
            })
          : []
      );
    }
    if (filterProps.selectedValues) {
      setMultiSelValues(filterProps.selectedValues ? filterProps.selectedValues : []);
    }
  }, [filterProps.data, filterProps.selectedValues]);

  const handleOnChange = (items: string[]) => {
    if (filterProps.onSelected) {
      filterProps.onSelected(
        items.map((val) => {
          if (filterProps.prefix) {
            return val.replaceAll(filterProps.prefix, "");
          } else {
            return val;
          }
        })
      );
    }
  };

  const deleteValues = () => {
    if (filterProps.onSelected) {
      filterProps.onSelected([]);
    }
  };

  const handleCreateElement = (query: string) => {
    const queryLabel = reformatQueryString(query, filterProps.prefix, filterProps.upperCaseText);
    if (query.length > 0 && data.filter((d) => d.label === queryLabel).length === 0) {
      const item = { value: queryLabel, label: queryLabel };
      setData((current) => [...current, item]);
      setMultiSelValues((current) => [...current, item.label]);
      return item;
    }
  };

  const createElemetBlock = (query: string) => {
    const element = reformatQueryString(query, filterProps.prefix, filterProps.upperCaseText);
    if (data.filter((d) => d.label === element).length === 0) {
      return `${element}`;
    }
  };

  return (
    <span className="creatable-multiselect">
      <MultiSelect
        data={data}
        value={multiSelValues}
        placeholder={filterProps.textPlaceholder}
        searchable
        clearable
        creatable
        getCreateLabel={createElemetBlock}
        onCreate={handleCreateElement}
        onChange={handleOnChange}
        disabled={filterProps.searchIsLoading || filterProps.disabled}
        onKeyPress={(event) => {
          if (filterProps.isNumeric && !/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
          if (filterProps.isAlphaNumeric && !/^[a-z0-9]+$/i.test(event.key)) {
            event.preventDefault();
          }
        }}
        rightSection={
          multiSelValues.length === 0 ? (
            <></>
          ) : (
            <div className={"d-flex pe-4"}>
              {multiSelValues.length > 2 ? (
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id="button-tooltip">
                      {multiSelValues.map((val: string, index: number) => {
                        return (
                          <div className={"text-start px-2 text-nowrap"} key={`${val}-${index}`}>
                            {val}
                          </div>
                        );
                      })}
                    </Tooltip>
                  }
                >
                  <Badge
                    bg={"secondary"}
                    pill
                    className="badge-icon float-start"
                    text={"light"}
                    style={{ background: "#363981 !important" }}
                  >
                    {multiSelValues.length}
                  </Badge>
                </OverlayTrigger>
              ) : (
                <Badge
                  bg={"secondary"}
                  pill
                  className="badge-icon float-start"
                  text={"light"}
                  style={{ background: "#363981 !important" }}
                >
                  {multiSelValues.length}
                </Badge>
              )}
              <CloseButton onClick={deleteValues} className={"float-start"} />
            </div>
          )
        }
        style={
          filterProps.widthFilter !== null ? { width: filterProps.widthFilter } : { width: "280px" }
        }
        styles={{
          defaultValue: {
            maxWidth: "80px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipse",
          },
        }}
        disableSelectedItemFiltering
        maxDropdownHeight={190}
      />
    </span>
  );
};
interface FilterProps {
  searchIsLoading?: boolean;
  titleFieldset?: string;
  textPlaceholder?: string;
  widthFilter?: string;
  data?: SelectItem[];
  onSelected?: Function;
  selectedValues?: string[];
  prefix?: string;
  isNumeric?: boolean;
  isAlphaNumeric?: boolean;
  upperCaseText?: boolean;
  disabled?: boolean;
}
export default CreatableMultiSelectComponent;
