export const formatYYYYMMDDToDate = (dateString: string | undefined): Date | null => {
  if (!dateString) return null;
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1; // Months are zero-based in JavaScript Date objects
  const day = parseInt(dateString.substring(6, 8));

  return new Date(year, month, day);
};

export const formatYYYYMMToDate = (dateString: string | undefined): Date | null => {
  if (!dateString) return null;
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1; // Months are zero-based in JavaScript Date objects

  return new Date(year, month, 1);
};

export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

export const formatDateToYYYYMM = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");

  return `${year}${month}`;
};

export const getOffsetDate = (offsetYears = 0, offsetMonths = 0, offsetDays = 0): Date => {
  let result = new Date();
  result.setFullYear(result.getFullYear() - offsetYears);
  result.setMonth(result.getMonth() - offsetMonths);
  result.setDate(result.getDate() - offsetDays);
  return result;
};

export const getFirstMonthOfQuarter = (date: Date): Date => {
  const month = date.getMonth();
  const newMonthIndex = Math.floor(month / 3) * 3;
  return new Date(date.getFullYear(), newMonthIndex, 1);
};

export const getLastMonthOfQuarter = (date: Date): Date => {
  const firstMonthOfQuarter = getFirstMonthOfQuarter(date);
  return new Date(firstMonthOfQuarter.getFullYear(), firstMonthOfQuarter.getMonth() + 2, 1);
};

export function convertToMonthYear(dateStr: string) {
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(5, 7);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${monthNames[parseInt(month) - 1]} ${year}`;
}
export function getLastDayOfMonth(year: number, month: number) {
  const nextMonth = new Date(year, month, 1);

  const lastDay = new Date(nextMonth.getTime() - 1);

  return lastDay.getDate();
}
