import React from "react";

import { ChartDataset } from "chart.js";
import { PricingVsDeclaredRevenu } from "../../../types/marketShareTypes";
import { PlatformColors } from "../../../components/platformColors";
import { formatCurrencyNumber } from "../../../helpers/formatter";
import i18n from "../../../i18n/i18n";
import { useAppSelector } from "../../../store/hooks";
import DoubleYAxisLineChart, { getXAxisValues } from "../../../components/doubleYAxisLineChart";

const PricingVsRevenue = ({ data }: { data: PricingVsDeclaredRevenu }) => {
  const lastSearchedValues = useAppSelector((state) => state.marketShare.lastSearchValues);

  const LineChartData = [
    {
      label: "Publishing pool",
      borderColor: PlatformColors[0],
      backgroundColor: PlatformColors[0],
      data: data.pricing_data.map((item) => {
        return {
          x: String(item.period),
          y: item.amount,
        };
      }),
      yAxisID: "y",
    } as ChartDataset<"line", { x: string; y: number }[]>,
    {
      label: "Declated revenue",
      borderColor: PlatformColors[1],
      backgroundColor: PlatformColors[1],

      data: data.revenue_data.map((item) => {
        return {
          x: String(item.period),
          y: item.amount,
        };
      }),
      yAxisID: "y1",
    } as ChartDataset<"line", { x: string; y: number }[]>,
  ];
  const tickFormatter = function (tickValue: number | string) {
    return formatCurrencyNumber(i18n.language, Number(tickValue));
  };

  return (
    <DoubleYAxisLineChart
      labels={getXAxisValues(
        lastSearchedValues.date.licenseKpi.startingDate,
        lastSearchedValues.date.licenseKpi.endingDate
      )}
      height={4}
      width={15}
      dataPoints={LineChartData}
      ticksFunction={tickFormatter}
    />
  );
};

export default PricingVsRevenue;
