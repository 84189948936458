import {
  AdvancedSearchExport,
  AdvancedSearchLicenses,
  PostAdvancedSearchRequestPayload,
} from "../types/advancedSearchTypes";
import {
  invokeRemoteDelete,
  invokeRemoteGet,
  invokeRemoteGetPathParam,
  invokeRemotePost,
} from "./api.utils";

export function getLicenses(params: { mandatorId: string | undefined }) {
  return invokeRemoteGet<AdvancedSearchLicenses, { mandatorId: string | undefined }>(
    "advanced-search-exports-licenses",
    params
  );
}

export function getAdvancedSearchExportsList(params: { sisterSocietyId: string | undefined }) {
  return invokeRemoteGet<AdvancedSearchExport[], { sisterSocietyId: string | undefined }>(
    "advanced-search-exports",
    params
  );
}

export function registerExport(payload: PostAdvancedSearchRequestPayload, params: any) {
  return invokeRemotePost<AdvancedSearchExport, PostAdvancedSearchRequestPayload>(
    "advanced-search-exports",
    payload,
    params
  );
}

export function getDownloadLink(id: string) {
  return invokeRemoteGetPathParam<string, string>("advanced-search-exports", id);
}

export function deleteAdvancedSearchExport(id: string) {
  return invokeRemoteDelete<string, string>("advanced-search-exports", id);
}
