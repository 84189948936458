import React from "react";
import { Container } from "react-bootstrap";
import "./Kpis.scss";
import TabsComponent from "../../components/tabsComponent";
import { t } from "i18next";
import KPIsFilters from "./KPIsHeader";
import MarketKpi from "./marketKpi";
import RepertoirePerformance from "./repertoirePerformance";
import "./commonComponents/graphsStyles.scss";
import { KpisTabs } from "./kpisHelper";
import LicenseKpi from "./licenseKpi";

const FiltersModel = (activeButtonIndex: number) => (
  <KPIsFilters activeTabIndex={activeButtonIndex} />
);
export const Kpis = () => {
  const elements = [
    {
      title: t("KPIs.tab-names.repertoire"),
      jsxFilters: FiltersModel,
      jsxPage: <RepertoirePerformance />,
      tabIndex: KpisTabs["repertoire-performance"],
      serviceTitle: "KPI",
    },
    {
      title: t("KPIs.tab-names.licence"),
      jsxFilters: FiltersModel,
      jsxPage: <LicenseKpi />,
      tabIndex: KpisTabs["licence-kpi"],
      serviceTitle: "KPI",
    },
    {
      title: t("KPIs.tab-names.market-kpi"),
      jsxFilters: FiltersModel,
      jsxPage: <MarketKpi />,
      tabIndex: KpisTabs["market-kpi"],
      serviceTitle: "KPI",
    },
  ];

  return (
    <Container fluid className="KPIs-page-container">
      <TabsComponent elements={elements} />
    </Container>
  );
};
