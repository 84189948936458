export const getReactQueryStatus = (
  data: any,
  isFetching: boolean,
  isError: boolean
) => {
  let status = -2

  if (isFetching) status = -1
  else if (isError) status = 500
  else if (data?.data != null) status = 200
  else if (data !== undefined && data.data === null) status = 204
  return status
}
