import React from "react";

import {
  ChartDataset,
  CoreScaleOptions,
  Scale,
  ScaleOptionsByType,
  Tick,
  TooltipItem,
  TooltipCallbacks,
} from "chart.js";
import { Col, Container, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { formatCurrencyNumber } from "../../helpers/formatter";
import moment from "moment";
import { DeepPartial } from "chart.js/types/utils";
import i18n from "../../i18n/i18n";
import { PlatformColors } from "../platformColors";

import "./doubleAxisLineChart.scss";
import { ScatterPoint } from "../../types";

const DATE_FORMAT = "YYYYMM";
export const getPointCoordinates = (context: TooltipItem<"line">) => {
  const {
    dataset: { label, data },
    dataIndex,
  } = context;
  const coordinates = data[dataIndex] as ScatterPoint;
  return { coordinates: coordinates, label: label };
};
const DoubleYAxisLineChart = ({
  dataPoints,
  labels,
  width,
  height,
  labelFunc = defaultLabelFunc,
  ticksFunction = function (value, index, ticks) {
    return value.toString();
  },
}: {
  dataPoints: ChartDataset<"line", { x: string; y: number }[]>[];
  labels?: DeepPartial<ScaleOptionsByType<"time">>;
  width: number;
  height: number;
  ticksFunction?: (
    this: Scale<CoreScaleOptions>,
    value: string | number,
    index: number,
    ticks: Tick[]
  ) => string;

  labelFunc?: () => DeepPartial<TooltipCallbacks<"line">>;
}) => {
  return (
    <>
      {dataPoints && (
        <Container className="py-5">
          <Line
            className={"courbe-style"}
            data={{ datasets: dataPoints }}
            width={width}
            height={height}
            options={{
              locale: i18n.language,
              plugins: {
                tooltip: {
                  callbacks: labelFunc(),
                },

                legend: {
                  display: false,
                },
              },

              scales: {
                x: labels,
                y: {
                  title: {
                    display: true,
                    text: "Publishing pool",
                    color: "black",
                    font: {
                      family: "Avenir",
                      style: "normal",
                      weight: "800",
                      size: 13,
                    },
                  },

                  ticks: {
                    callback: ticksFunction,
                  },
                  position: "right" as const,
                },
                y1: {
                  title: {
                    display: true,
                    text: "Declared revenue",
                    color: "black",
                    font: {
                      family: "Avenir",
                      style: "normal",
                      weight: "800",
                      size: 13,
                    },
                  },

                  ticks: {
                    callback: ticksFunction,
                  },
                  position: "left" as const,
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
          <Container>
            <Row className="justify-content-center" style={{ marginTop: "50px" }}>
              <LegendItem label={"Declared Revenue"} backgroundColor={PlatformColors[1]} />
              <LegendItem label={"Publishing pool"} backgroundColor={PlatformColors[0]} />
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};

export default DoubleYAxisLineChart;

export function getXAxisValues(
  startDate: string,
  endDate: string,
  lang: "fr" | "en" = "en"
): DeepPartial<ScaleOptionsByType<"time">> {
  moment.locale(lang);

  return {
    type: "time",
    min:
      moment(startDate, DATE_FORMAT).year() + moment(startDate, DATE_FORMAT).quarter().toString(),
    max: moment(endDate, DATE_FORMAT).year() + moment(endDate, DATE_FORMAT).quarter().toString(),
    time: {
      parser: "YYYYQ",
      unit: "quarter",
    },
    ticks: {
      autoSkip: false,
      maxRotation: 35,
      minRotation: 35,
      callback: function (value, index, ticks) {
        const momentValue = moment(ticks[index].value);
        const year = momentValue.year();
        const quarter = momentValue.quarter();
        return `${year}Q${quarter}`;
      },
    },
  };
}

function defaultLabelFunc(): DeepPartial<TooltipCallbacks<"line">> {
  return {
    title: function (context: TooltipItem<"line">[]): string | string[] {
      const { coordinates } = getPointCoordinates(context[0]);
      const coordinatesStringValue = coordinates.x.toString().replace("T", "");
      return coordinatesStringValue.substring(0, 4) + "Q" + coordinatesStringValue.substring(4);
    },
    label: function (context: TooltipItem<"line">) {
      const {
        dataset: { label, data },
        dataIndex,
      } = context;
      const value = (data[dataIndex] as ScatterPoint).y;
      return ` ${label} : ${formatCurrencyNumber(i18n.language, value)}`;
    },
  };
}

const LegendItem = (props: { label: string; backgroundColor: string }) => {
  return (
    <Col className="double-axis-legend-item" xs={3}>
      <div
        className="title-color"
        style={{
          backgroundColor: props.backgroundColor,
        }}
      />
      <div className="title">{props.label}</div>
    </Col>
  );
};
