import React, { useEffect } from 'react'
import './dataTableMantine.scss'
import { DataTableMantineProps } from './dataTableMantine.model'
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table'

const DataTableMantine = <T extends Record<string, any>>({
  columns,
  data: mantineData,
  isLoading,
  tableProps = {},
}: DataTableMantineProps<T>) => {
  const DefaultTableProps: MRT_TableOptions<T> = {
    columns: columns as MRT_ColumnDef<T>[],
    data: mantineData, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableHiding: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableClickToCopy: false,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableStickyHeader: false,
    enableDensityToggle: false,
    state: { showSkeletons: isLoading, density: 'xs' },
    mantineTableProps: {
      className: 'custom-mantine-table',
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableHeadCellProps: {
      className: 'custom-mantine-table-header',
    },
    mantineTableBodyCellProps: {
      className: 'custom-mantine-table-body-cells',
    },
    mantineFilterTextInputProps: {
      className: 'custom-mantine-table-edit-text-input',
    },
    mantineTopToolbarProps: {
      className: 'custom-mantine-table-top-toolbar',
    },
    mantineBottomToolbarProps: {
      className: 'custom-pagination-list',
    },
    mantinePaginationProps: {
      rowsPerPageOptions: ['10', '30', '50'],
    },
  }

  const actionableTableProps = { ...DefaultTableProps, ...tableProps }

  const table = useMantineReactTable(actionableTableProps)
  useEffect(() => {
    table.resetColumnFilters()
  }, [isLoading])

  return <MantineReactTable table={table} />
}

export default DataTableMantine
