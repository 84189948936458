import React, { ChangeEvent, useEffect, useState } from "react";
import { TextInput } from "@mantine/core";

const InputTextComponent = (filterProps: FilterProps) => {
  const [textValue, setTextValue] = useState<string>(
    filterProps.selectedValue ? filterProps.selectedValue : ""
  );
  useEffect(() => {
    setTextValue(filterProps.selectedValue != null ? filterProps.selectedValue : "");
  }, [filterProps.selectedValue]);
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let searchText = filterProps.upperCaseText
      ? event.target.value.toUpperCase()
      : event.target.value;
    if (filterProps.isNumeric) {
      searchText = searchText.replace(/[^0-9]/g, "");
    }

    setTextValue(searchText);
    if (filterProps.handleComponentChange) {
      filterProps.handleComponentChange(searchText);
    }
  };

  return (
    <TextInput
      placeholder={filterProps.textPlaceholder}
      onChange={onChangeHandler}
      value={`${textValue.length > 0 && filterProps.prefix ? filterProps.prefix : ""}${textValue}`} // Add "T" prefix to the value
      onKeyPress={(event) => {
        if (filterProps.isNumeric && !/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      style={
        filterProps.widthFilter !== null ? { width: filterProps.widthFilter } : { width: "280px" }
      }
      disabled={filterProps.searchIsLoading || filterProps.disabled}
    />
  );
};
interface FilterProps {
  searchIsLoading?: boolean;
  textPlaceholder?: string;
  widthFilter?: string;
  selectedValue?: string;
  handleComponentChange?: Function;
  upperCaseText?: boolean;
  isNumeric?: boolean;
  disabled?: boolean;
  prefix?: string;
}

export default InputTextComponent;
