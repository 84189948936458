import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { Col, Container, Row } from "react-bootstrap";
import { renderMarketShareBlock } from "../commonComponents/utiliRenderers";
const MarketKpi = () => {
  const declaredRevenueData = useAppSelector(
    (state) => state.marketShare.marketKpisData.declaredRevenue
  );

  const declaredRevenueStatus = useAppSelector(
    (state) => state.marketShare.status.marketKpis.declaredRevenue
  );

  const numberOfStreamsData = useAppSelector(
    (state) => state.marketShare.marketKpisData.numberOfStreams
  );

  const numberOfStreamsStatus = useAppSelector(
    (state) => state.marketShare.status.marketKpis.numberOfStreams
  );

  const numberOfUsersData = useAppSelector(
    (state) => state.marketShare.marketKpisData.numberOfUsers
  );

  const numberOfUsersStatus = useAppSelector(
    (state) => state.marketShare.status.marketKpis.numberOfUsers
  );

  const avgDeclaredRevenuePerUserData = useAppSelector(
    (state) => state.marketShare.marketKpisData.avgDeclaredRevenuePerUser
  );

  const avgDeclaredRevenuePerUserStatus = useAppSelector(
    (state) => state.marketShare.status.marketKpis.avgDeclaredRevenuePerUser
  );

  const graphQuarterInterval = useAppSelector(
    (state) => state.marketShare.lastSearchValues.date.marketKpi
  );

  return (
    <Container className="market-kpi-content">
      <Row style={{ marginTop: "50px" }}>
        <Col md={6}>
          {renderMarketShareBlock(
            declaredRevenueData,
            [],
            declaredRevenueStatus,
            graphQuarterInterval,
            "currency",
            "market-kpi.declared-revenue",
            "bars"
          )}
        </Col>
        <Col md={6}>
          {renderMarketShareBlock(
            numberOfStreamsData,
            [],
            numberOfStreamsStatus,
            graphQuarterInterval,
            "int",
            "market-kpi.streams-per-commercial-models",
            "bars"
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "50px", paddingBottom: "25px" }}>
        <Col md={6}>
          {renderMarketShareBlock(
            numberOfUsersData,
            [],
            numberOfUsersStatus,
            graphQuarterInterval,
            "int",
            "market-kpi.number-of-users",
            "bars"
          )}
        </Col>
        <Col md={6}>
          {renderMarketShareBlock(
            [],
            avgDeclaredRevenuePerUserData,
            avgDeclaredRevenuePerUserStatus,
            graphQuarterInterval,
            "currency",
            "market-kpi.average-declared-revenue-per-user",
            "lines"
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MarketKpi;
