import React, { useEffect, useRef, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TextWithHoverOnOverflow = ({ text }: { text: string }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const isEllipsisActive = (e: HTMLDivElement) => {
    return e.offsetWidth < e.scrollWidth
  }
  useEffect(() => {
    const element = textRef?.current
    if (element) {
      setIsOverflowing(isEllipsisActive(element))
    }
  }, [])

  if (!text || text.length === 0) {
    return <></>
  }
  return (
    <>
      {isOverflowing ? (
        <div style={{ display: 'flex' }}>
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id='button-tooltip'>
                <>{text}</>
              </Tooltip>
            }
          >
            <div
              ref={textRef}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </div>
          </OverlayTrigger>
        </div>
      ) : (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          ref={textRef}
        >
          {text}
        </div>
      )}
    </>
  )
}

export default TextWithHoverOnOverflow
