import React, { useContext, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { ChartDataset } from "chart.js";
import { useAppSelector } from "../../../store/hooks";
import { statusMessages } from "../../../utils/statusMessages";
import {
  RepertoirePerformanceTotalAmountData,
  MarketShareByDSP,
  MarketShareByDSPCommercialModel,
  MultiSelectFilterType,
  MarketShareByClaimType,
  StringDateRange,
  MarketShareSearchParams,
  MarketShareByDspOfferXlsx,
} from "../../../types/marketShareTypes";
import { ProgressBarGroup, ProgressBarGroupItem } from "../../../components/progressBarGroup";
import { offerBarColors } from "../../../components/stackedBarChart/barColors";
import BoxSectionComponent from "../../../components/boxSectionComponent";
import { StackedBarChart } from "../../../components/stackedBarChart";
import { HistogrammeColors } from "../../../components/platformColors";
import { formatCurrencyNumber, formatNumber } from "../../../helpers/formatter";
import { getPercentageYOptions, getXOptions } from "../../../helpers/chartHelper";

import {
  prepareExportByCommercialModelData,
  prepareExportByClaimTypeData,
  MarketShareSearchParamsSelection,
} from "../marketShareExportDataHandler";

import "./index.scss";

const RepertoirePerformance = () => {
  const { t, i18n } = useTranslation();

  const totalAmountStatus: number = useAppSelector(
    (state) => state.marketShare.status.repertoirePerformance.totalAmount
  );
  const totalAmountData: RepertoirePerformanceTotalAmountData = useAppSelector(
    (state) => state.marketShare.repertoirePerformance.totalAmount
  );

  const marketShareByCommercialModelStatus: number = useAppSelector(
    (state) => state.marketShare.status.repertoirePerformance.marketShareByCommercialModel
  );
  const marketShareByCommercialModelData: MarketShareByDSP[] = useAppSelector(
    (state) => state.marketShare.repertoirePerformance.marketShareByCommercialModel
  );
  const marketShareByClaimTypeStatus: number = useAppSelector(
    (state) => state.marketShare.status.repertoirePerformance.marketShareByClaimType
  );
  const marketShareByClaimTypeData: MarketShareByClaimType[] = useAppSelector(
    (state) => state.marketShare.repertoirePerformance.marketShareByClaimType
  );

  const allDSPs: MultiSelectFilterType<string>[] = useAppSelector(
    (state) => state.marketShare.filters.dsps
  );

  const marketShareSearchParams: MarketShareSearchParams = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams
  );
  const allTerritories = useAppSelector((state) => state.marketShare.filters.territories);
  const allGroupedOffers = useAppSelector((state) => state.marketShare.filters.groupedOffers);
  const allRepositories = useAppSelector((state) => state.appContext.repertoires.data);
  const selectedRepertoires = useAppSelector((state) => state.appContext.selectedRepertoire);

  const renderTotalAmountDataBox = (formattedAmount: string, descriptionLabelId: string) => {
    return (
      <div className="repertoire-performance-value-box">
        {totalAmountStatus === -1 ? (
          <BeatLoader speedMultiplier={1} size={15} color="white" loading={true} />
        ) : (
          <div className="repertoire-performance-value-amount">
            {totalAmountStatus === 200 ? formattedAmount : "-"}
          </div>
        )}
        <div className="repertoire-performance-value-text">{t(descriptionLabelId)}</div>
      </div>
    );
  };

  const getDspLabel = (dspCode: string) => {
    const referenceDsp = allDSPs.find((dsp) => dsp.code === dspCode)?.label;

    return referenceDsp ? referenceDsp : dspCode;
  };

  function AccordionHeader({ eventKey, item, i18n }: any) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <div className="market-share-by-commercial-model-container" onClick={decoratedOnClick}>
        <div style={{ display: "flex" }}>
          <div
            className={`expand-arrow ${activeEventKey?.includes(eventKey) ? "open-arrow" : ""}`}
          ></div>
          <div className="bar-label">{getDspLabel(item.dspCode)}</div>
        </div>
        <span className="bar-amount">
          {formatCurrencyNumber(i18n.language, item.totalClaimedAmount)}
        </span>
      </div>
    );
  }

  const getCommercialModelsInfoAsProgressBarGroupItems = (
    marketShareDSPInformation: MarketShareByDSP
  ) => {
    const commercialModelsInfoAsProgressBarGroupItems: ProgressBarGroupItem[] =
      marketShareDSPInformation.detailsPerCommercialModel.map(
        (dspCommercialModelInformation: MarketShareByDSPCommercialModel) => ({
          code: dspCommercialModelInformation.commercialModelId,
          displayName: dspCommercialModelInformation.commercialModelName,
          value: formatCurrencyNumber(
            i18n.language,
            dspCommercialModelInformation.claimedAmountEuros
          ),
          percentage: dspCommercialModelInformation.marketShare,
          color: offerBarColors[dspCommercialModelInformation.commercialModelId],
        })
      );
    return commercialModelsInfoAsProgressBarGroupItems;
  };

  const tooltipFormatNumbersCallback = (): any => {
    return {
      label: function (context: any) {
        let label = context.dataset.label || "";

        if (label) {
          label += ` ${t("market-kpi.stacked-bar.tooltip.ms-label")}: `;
        }
        if (context.parsed.y !== null) {
          label += formatNumber(i18n.language, context.parsed.y);
        }
        return label + "%";
      },
      footer: function (context: any) {
        let label = `${t("market-kpi.stacked-bar.tooltip.ms-claimed-amount")}: `;
        if (context[0].parsed.y) {
          label += formatCurrencyNumber(
            i18n.language,
            context[0].dataset.data[context[0].dataIndex].amount
          );
        }
        return label;
      },
    };
  };

  function getSelectedData<T>(
    selectedData: T[] | undefined,
    allItems: MultiSelectFilterType<T>[]
  ): string[] {
    if (selectedData == null || selectedData.length === 0) {
      return allItems.map((item) => item.label);
    }
    return allItems.filter((item) => selectedData.includes(item.code)).map((item) => item.label);
  }

  function getSelectedRepertoire<T>(
    selectedRepertoires: any,
    allItems: MultiSelectFilterType<T>[]
  ): string[] {
    if (selectedRepertoires == "all-repertoires") {
      return ["All"];
    }
    return allItems
      .filter((item) => selectedRepertoires.includes(item.code))
      .map((item) => item.label);
  }

  function getSearchedParamsForExport(): MarketShareSearchParamsSelection {
    const selectedDSPs = getSelectedData(marketShareSearchParams?.dsps, allDSPs);
    const selectedRepositories = getSelectedRepertoire(selectedRepertoires, allRepositories);
    const selectedTerritories = getSelectedData(
      marketShareSearchParams?.territories,
      allTerritories
    );
    const selectedGroupedOffers = getSelectedData(
      marketShareSearchParams?.groupedOffers,
      allGroupedOffers
    );
    return {
      date: marketShareSearchParams?.date as StringDateRange,
      dsps: selectedDSPs,
      allDsps: false, // Force the DSPs to 'false' as all DSPs can be relevant (and there are not many DSPs)
      groupedOffers: selectedGroupedOffers,
      allGroupedOffers: false, // Force the grouped offers to 'false' as there are not many grouped offers
      repositories: selectedRepositories,
      allRepositories: selectedRepositories.length === allRepositories.length,
      territories: selectedTerritories,
      allTerritories: selectedTerritories.length === allTerritories.length,
    };
  }
  const formatMSBOData = () => {
    const marketShareByDspOfferXlsx: MarketShareByDspOfferXlsx[] = [];
    marketShareByCommercialModelData.forEach((item) => {
      item.detailsPerCommercialModel.forEach((detail, i) => {
        const xlsxRow: MarketShareByDspOfferXlsx = {
          ...detail,
          claimedAmountEuros: formatCurrencyNumber(
            i18n.language,
            parseFloat(detail.claimedAmountEuros.toString())
          ),
          pdm: `${detail.marketShare}%`,
          dspCode: "",
          totalAmount: "",
        };
        if (i === 0) {
          xlsxRow.dspCode = item.dspCode;
          xlsxRow.totalAmount = formatCurrencyNumber(
            i18n.language,
            parseFloat(item.totalClaimedAmount.toString())
          );
        }
        marketShareByDspOfferXlsx.push(xlsxRow);
      });
    });
    return prepareExportByCommercialModelData(
      t,
      getSearchedParamsForExport(),
      marketShareByDspOfferXlsx
    );
  };

  const formatMSBCTData = () => {
    return prepareExportByClaimTypeData(
      t,
      getSearchedParamsForExport(),
      marketShareByClaimTypeData
    );
  };

  const chartData: {
    labels: string[];
    datasets: ChartDataset<
      "bar",
      { periodExploit: string; percentage: number; amount: number }[]
    >[];
  } = useMemo(() => {
    //distinct claim type date list
    const distinctClaimTypeDateList = [
      ...new Set(marketShareByClaimTypeData.map((m) => m.periodExploit)),
    ];
    const claimTypeNameList = [
      ...new Set(marketShareByClaimTypeData.map((msbc) => msbc.claimType)),
    ];

    const mappedChartData: ChartDataset<
      "bar",
      { periodExploit: string; percentage: number; amount: number }[]
    >[] = [];
    //distinct claim type name list
    claimTypeNameList.forEach((ctName: string, index: number) => {
      mappedChartData.push({
        label: ctName,
        data: marketShareByClaimTypeData
          .filter((item) => item.claimType == ctName)
          .map((item) => ({
            periodExploit: item.periodExploit,
            percentage: item.percentage,
            amount: item.claimedAmount,
          })),
        borderRadius: 4,
        hidden: false,
        backgroundColor: HistogrammeColors[index % HistogrammeColors.length],
        parsing: {
          xAxisKey: "periodExploit",
          yAxisKey: "percentage",
        },
      });
    });
    return { labels: distinctClaimTypeDateList, datasets: mappedChartData };
  }, [marketShareByClaimTypeData]);

  const renderMarketShareByCommercialModelDspAccordions = () => {
    return (
      <div className={"p-3"}>
        <Accordion alwaysOpen>
          {marketShareByCommercialModelData.map(
            (marketShareDSPInformation: MarketShareByDSP, i) => (
              <Card key={i}>
                <Card.Header>
                  <AccordionHeader
                    eventKey={String(i)}
                    item={marketShareDSPInformation}
                    i18n={i18n}
                  />
                </Card.Header>
                {i < marketShareByCommercialModelData.length - 1 && (
                  <div className="cards-separator"></div>
                )}
                <Accordion.Collapse eventKey={`${i}`}>
                  <Card.Body className="padding-unset">
                    <ProgressBarGroup
                      items={getCommercialModelsInfoAsProgressBarGroupItems(
                        marketShareDSPInformation
                      )}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          )}
        </Accordion>
      </div>
    );
  };

  const renderMarketShareByCommercialModelBox = () => {
    return (
      <BoxSectionComponent
        title={t("market-share.ms-by-offer-card-header")}
        exportButtonOptions={{
          getData: formatMSBOData,
          exportType: "Sacem_marketShare_by_dsps_offers",
          titleExport: t("market-share.export.btn-export"),
          opts: { skipHeader: true },
          colOptions: [{ wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }],
        }}
        showExportButton={marketShareByCommercialModelStatus === 200}
        status={{
          statusCode: marketShareByCommercialModelStatus,
          defaultMessage: statusMessages.defaultMessage,
          noDataFoundMessage: statusMessages.noDataFoundMessage,
          unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
        }}
        bodyContent={renderMarketShareByCommercialModelDspAccordions()}
      />
    );
  };

  const renderMarketShareByClaimTypeBox = () => {
    return (
      <BoxSectionComponent
        title={t("market-share.ms-by-claim-type-card-header")}
        exportButtonOptions={{
          getData: formatMSBCTData,
          exportType: "Sacem_marketShare_claimType",
          titleExport: t("market-share.export.btn-export"),
          opts: { skipHeader: true },
          colOptions: [
            { wch: 20 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
          ],
        }}
        showExportButton={marketShareByClaimTypeStatus === 200}
        status={{
          statusCode: marketShareByClaimTypeStatus,
          defaultMessage: statusMessages.defaultMessage,
          noDataFoundMessage: statusMessages.noDataFoundMessage,
          unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
        }}
        bodyContent={
          <div className={"p-3"}>
            <StackedBarChart<{
              periodExploit: string;
              percentage: number;
              amount: number;
            }>
              labels={chartData.labels}
              datasets={chartData.datasets}
              xOptions={getXOptions({
                lang: i18n.language,
                autoSkip: true,
              })}
              yOptions={getPercentageYOptions({
                autoSkip: false,
                beginAtZero: true,
              })}
              lang={i18n.language}
              stackedBarTooltipCallbacks={tooltipFormatNumbersCallback()}
            />
          </div>
        }
      />
    );
  };

  return (
    <Container className="repertoire-performance-container">
      <Row className="my-4">
        <Col md={6}>
          {renderTotalAmountDataBox(
            formatCurrencyNumber(i18n.language, totalAmountData.claimedAmount || 0),
            "market-share.ms-total-amount"
          )}
        </Col>
        <Col md={6}>
          {renderTotalAmountDataBox(
            totalAmountData?.percentage ? totalAmountData.percentage.toString() + "%" : "0%",
            "market-share.ms-total"
          )}
        </Col>
      </Row>
      <Row className={"mb-4"}>
        <Col md={6} style={{ height: "500px" }}>
          {renderMarketShareByCommercialModelBox()}
        </Col>
        <Col md={6} style={{ height: "500px" }}>
          {renderMarketShareByClaimTypeBox()}
        </Col>
      </Row>
    </Container>
  );
};

export default RepertoirePerformance;
