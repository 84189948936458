import React, { useEffect, useState } from "react";
import AutoCompleteComponent from "./AutoCompleteComponent";
import { AutocompleteItem, SelectItem } from "@mantine/core";
import MultiSelectComponent from "./multiSelectComponent";
import MonoSelectComponent from "./monoSelectComponent";
import "./filtersComponent.scss";
import DateRangeComponent from "./dateRangeComponent";
import DateQuarterRangeComponent from "./dateQuarterRange";
import InputTextComponent from "./inputTextComponent";
import CreatableMultiSelectComponent from "./creatableMultiselectComponent";
import MultiSelectAutoCompleteComponent from "./MultiSelectAutoCompleteComponent";
import { MultiSelectAutoCompleteItem } from "../../types";
import DateMonthRangeComponent from "./dateMonthRange";

const FiltersComponent = (filterProps: FilterProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(filterProps.statusArray ? filterProps.statusArray.indexOf(-1) !== -1 : false);
  }, [filterProps.statusArray]);

  const filterInputHandler = (type: string) => {
    switch (type) {
      case "autocomplete":
        return (
          <AutoCompleteComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            data={filterProps.autocompleteData ? filterProps.autocompleteData : []}
            onFilterChange={filterProps.handleComponentChange}
            onSelected={filterProps.handleOnClick}
            status={filterProps.status}
            widthFilter={filterProps.widthFilter}
            selectedValue={filterProps.selectedValue}
            disabled={filterProps.disabled}
          />
        );
      case "monoSelect":
        return (
          <MonoSelectComponent
            keepInitialDataSort={filterProps.keepInitialDataSort}
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            widthFilter={filterProps.widthFilter}
            data={filterProps.data ? filterProps.data : []}
            status={filterProps.status}
            onSelected={filterProps.handleOnClick}
            selectedValue={filterProps.selectedValue}
            disabled={filterProps.disabled}
          />
        );
      case "multiSelect":
        return (
          <MultiSelectComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            data={filterProps.data ? filterProps.data : []}
            onSelected={filterProps.handleComponentChange}
            selectedValues={filterProps.selectedValues ? filterProps.selectedValues : []}
            status={filterProps.status}
            widthFilter={filterProps.widthFilter}
            disabled={filterProps.disabled}
          />
        );
      case "multiSelectAutoComplete":
        return (
          <MultiSelectAutoCompleteComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            noDataMessage={filterProps.noDataMessage}
            data={(filterProps.data as MultiSelectAutoCompleteItem[]) || []}
            previousData={filterProps.previousData || []}
            onSelected={filterProps.handleComponentChange}
            status={filterProps.status as number}
            widthFilter={filterProps.widthFilter}
            disabled={filterProps.disabled}
            handleActionFetch={filterProps.handleActionFetch}
            searchClicked={filterProps.searchClicked}
          />
        );
      case "simpleDateRange":
        return (
          <DateRangeComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            handleComponentChange={filterProps.handleComponentChange}
            minDate={filterProps.minDate}
            maxDate={filterProps.maxDate}
            widthFilter={filterProps.widthFilter}
            startDate={filterProps.startDate}
            endDate={filterProps.endDate}
            dateFormat={filterProps.dateFormat}
          />
        );

      case "quarterDateRange":
        return (
          <DateQuarterRangeComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            handleComponentChange={filterProps.handleComponentChange}
            minDate={filterProps.minDate}
            maxDate={filterProps.maxDate}
            widthFilter={filterProps.widthFilter}
            startDate={filterProps.startDate}
            endDate={filterProps.endDate}
            dateFormat={filterProps.dateFormat}
          />
        );
      case "monthDateRange":
        return (
          <DateMonthRangeComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            handleComponentChange={filterProps.handleComponentChange}
            minDate={filterProps.minDate}
            maxDate={filterProps.maxDate}
            widthFilter={filterProps.widthFilter}
            startDate={filterProps.startDate}
            endDate={filterProps.endDate}
            dateFormat={filterProps.dateFormat}
          />
        );
      case "inputText":
        return (
          <InputTextComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            handleComponentChange={filterProps.handleComponentChange}
            widthFilter={filterProps.widthFilter}
            selectedValue={filterProps.selectedValue}
            upperCaseText={filterProps.upperCaseText}
            isNumeric={filterProps.isNumeric ? filterProps.isNumeric : false}
            disabled={filterProps.disabled}
            prefix={filterProps.prefix}
          />
        );
      case "creatableMultiSelect":
        return (
          <CreatableMultiSelectComponent
            searchIsLoading={isLoading}
            textPlaceholder={filterProps.textPlaceholder}
            onSelected={filterProps.handleComponentChange}
            widthFilter={filterProps.widthFilter}
            selectedValues={filterProps.selectedValues}
            data={filterProps.data}
            prefix={filterProps.prefix}
            isNumeric={filterProps.isNumeric}
            isAlphaNumeric={filterProps.isAlphaNumeric}
            upperCaseText={filterProps.upperCaseText}
            disabled={filterProps.disabled}
          />
        );
    }
  };

  return <>{filterInputHandler(filterProps.filterType)}</>;
};

export default FiltersComponent;

interface FilterProps {
  keepInitialDataSort?: boolean;
  statusArray?: number[];
  titleFieldset?: string;
  textPlaceholder: string;
  noDataMessage?: string;
  widthFilter?: string;
  filterType:
    | "autocomplete"
    | "multiSelect"
    | "multiSelectAutoComplete"
    | "monoSelect"
    | "simpleDateRange"
    | "quarterDateRange"
    | "inputText"
    | "creatableMultiSelect"
    | "monoSelectWithInputComponent"
    | "monthDateRange";

  data?: Array<SelectItem> | Array<MultiSelectAutoCompleteItem>;
  previousData?: Array<MultiSelectAutoCompleteItem>;
  disabled?: boolean;
  autocompleteData?: Array<string | AutocompleteItem>;
  handleComponentChange?: Function;
  handleActionFetch?: any;
  selectedValue?: string;
  selectedValues?: string[];
  status?: number;
  handleOnClick?: Function;
  code?: string;
  startDate?: string;
  endDate?: string;
  maxDate?: Date;
  minDate?: Date;
  dateFormat?: string;
  MonthPickerBootstrapColumnsNumber?: string;
  prefix?: string;
  isNumeric?: boolean;
  isAlphaNumeric?: boolean;
  upperCaseText?: boolean;
  searchClicked?: boolean;
}
