import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { selectTypicalPrograms } from '../../slices/setListsSlice';
import { useAppSelector } from '../../store/hooks';
import { setBreadcrumb } from '../../slices/appContextSlice';
import { AppContextState, AppPropsType, FormProgramInterface } from '../../types';
import NavigateBackButton from '../../components/navigateBack';
import { postWorksListAsync } from '../../slices/setListsSlice';
import WebsoStepper from './components/websoStepper';
import SecondStep from './steps/second-step';
import ThirdStep from './steps/third-step';
import FirstStep from './steps/first-step';
import { ROLES, ROUTES } from '../../constants';
import useRoleRedirect from '../../hooks/useRoleRedirect';
import './index.scss';

const CreateProgram = (props: Props) => {
  useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const { appContext } = props;
	const initialValues = {
		worksListTitle: '',
		worksListStartDate: moment().format('Y-MM-DD'),
		worksListTypeCode: 'AI',
		memberId: appContext.selectedSisterSociety?.sisterSocietyId
	};

	const program = useAppSelector(selectTypicalPrograms);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [indexStep, setIndexStep] = useState<number>(0);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [values, setValues] = useState<FormProgramInterface>(initialValues);

	useEffect(() => {
		dispatch(setBreadcrumb({
			title: t('page.page-new-program'),
			showHome: true
		}));
		if (program.newProgram.worksListId){
			setValues({
				worksListId: program.newProgram.worksListId,
				worksListStartDate: program.newProgram.worksListStartDate,
				worksListTitle: program.newProgram.worksListTitle,
				worksListTypeCode: program.newProgram.worksListTypeCode,
			});
		}
	}, []);


	useEffect(() => {
		if ('DRAFT_WORK_LIST_OK' === program.responseCode) {
			navigate(ROUTES.GET_SETLISTS + '?draft_mode=true');
		}
		if (indexStep === 0 && 'CREATE_WORK_LIST_OK' === program.responseCode) {
			setValues({ worksListId: program.newProgram.worksListId, ...values });
			setIndexStep(1);
			setCurrentStep(1);

		}
	}, [program]);

	const goBack = () => {
		if (1 === indexStep) {
			setIndexStep(0);
		} else if (2 === indexStep) {
			setIndexStep(1);
		} else {
			navigate(ROUTES.GET_SETLISTS);
		}
	};

	const handleSteps = (step:number) => {
		setIndexStep(step);
	};

	const saveDraft = async () => {
		if (appContext.selectedSisterSociety?.sisterSocietyId) {
			dispatch(postWorksListAsync({
				values,
				societyId: appContext.selectedSisterSociety?.sisterSocietyId,
				isDraft: true,
			}));
		} else {
			// @todo show error message
		}
	};

	const handleSubmit = (step: number, values: any) => {
		if (indexStep === 0 && appContext.selectedSisterSociety?.sisterSocietyId) {
			dispatch(postWorksListAsync({
				values,
				societyId: appContext.selectedSisterSociety?.sisterSocietyId,
				isDraft: false,
			}));
		}
		if (indexStep === 1) {
			setIndexStep(2);
			setCurrentStep(2);
		}
	};

	return (
		<Container>
			<Row>
				<Col xs lg={{ span: 3 }} className='mt-2'>
					<NavigateBackButton goBackProps={goBack} />
				</Col>
			</Row>
			<Row>
				<div className="stepper d-flex justify-content-center">
					<Col className={`mb-4 d-flex justify-content-end ${indexStep === 0 ? 'col-6' : indexStep === 1 ? 'col-12' : 'col-7'}`}>
						<WebsoStepper handleSteps={handleSteps} step={indexStep} currentStep={currentStep}/>
					</Col>
				</div>
			</Row>
			<Row>
				<div className="form d-flex justify-content-center">
					<div className={`d-flex justify-content-end ${indexStep === 0 ? 'col-6' : indexStep === 1 ? 'col-12' : 'col-8'}`}>
						{indexStep === 1 ?
							<SecondStep onSubmit={handleSubmit} /> :
							indexStep === 2 ?
								<ThirdStep saveDraft={saveDraft} setStep={setIndexStep} valuesProgram={values}/> :
								<FirstStep values={values} onSubmit={handleSubmit} saveDraft={saveDraft}/>
						}
					</div>
				</div>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	appContext: state.appContext
});

export default connect(mapStateToProps) (CreateProgram);

interface Props {
	appContext: AppContextState
};