import { IMapObject, OnRegionTipShow } from "@react-jvectormap/core/dist/types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "i18next";
import { abbreviateNumber } from "js-abbreviation-number";

import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../helpers/formatter";
import { vectorMapAllowedRegions } from "../../../constants/constants";
import WorldMap from "../../../components/world-map";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const blueDegradedColors: string[] = [
  "#E4EAFF",
  "#C5D4FC",
  "#A6BCFC",
  "#8FA9FA",
  "#6687FA",
  "#6885de",
  "#4B6CDA",
  "#3251BA",
  "#1E3997",
  "#03175e",
];
const maxOfArray = (arr: number[]) => {
  return Math.max(...arr);
};
const numberAbbreviation = (number: number) => {
  const symbolsList: string[] = ["", ...t("utils.symbols_list").split(" ")];
  return abbreviateNumber(number, 1, { symbols: symbolsList });
};
interface StreamsPerTerritoryType {
  territory_alpha2_code: string;
  price: number;
}
interface LicenseKpiMapPropType {
  allTerritories: string[];
  streamsPerTerritory: StreamsPerTerritoryType[];
}

const StreamsPerTerritory = ({ allTerritories, streamsPerTerritory }: LicenseKpiMapPropType) => {
  const { i18n } = useTranslation();

  const mapRef = useRef<IMapObject>(null);

  const { screenWidth } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState<boolean>(screenWidth < 1176);

  const highestPricePerStream: number = maxOfArray(
    streamsPerTerritory.map((payout: StreamsPerTerritoryType) => {
      return payout.price;
    })
  );
  const getStreamPricePerTerritory = (code: string) => {
    const currentPayout: StreamsPerTerritoryType = streamsPerTerritory.filter(
      (payout: StreamsPerTerritoryType) => {
        return payout.territory_alpha2_code === code;
      }
    )[0];
    return currentPayout?.price;
  };

  const getTerritoryColor = (code: string) => {
    const streamPricePercentageToMax: string = Math.round(
      (getStreamPricePerTerritory(code) / highestPricePerStream) * 100
    ).toString();

    const colorIndex: number =
      Number(streamPricePercentageToMax) === 100
        ? blueDegradedColors.length - 1
        : Number(streamPricePercentageToMax) < 10
          ? 0
          : Number(streamPricePercentageToMax.charAt(0));

    return blueDegradedColors[colorIndex];
  };

  const onRegionTipShow: OnRegionTipShow = (event, el: any, code) => {
    if (!allTerritories.includes(code)) {
      event.preventDefault();
      return;
    }

    const countryFullName = mapRef?.current?.getRegionName(code) as any as string;
    const pricePerTerritory: number = getStreamPricePerTerritory(code);
    if (!isMobile) {
      return el.html(
        `<div class='country-hover-info'>${t(
          "license-kpi.sections.per-1000-streams.boxes.per-territory.map-hover",
          {
            country_name: countryFullName,
            price: formatNumber(i18n.language, pricePerTerritory),
          }
        )}</div>`
      );
    } else {
      event.preventDefault();
    }
  };
  const regionStyle = (code: string) => {
    //return a style object for regions states initial, hover
    if (allTerritories.includes(code)) {
      return {
        initial: {
          fill: getTerritoryColor(code),

          cursor: "pointer",
          "stroke-width": 0.2,
          "stroke-opacity": 0.6,
          stroke: "blue",
        },
        hover: {
          fill: "#abdbe3",
        },
      };
    } else {
      return {};
    }
  };
  const zoomableTerritories: string[] = allTerritories.filter((terr) => {
    const alphaNumeric = countries.alpha2ToNumeric(terr);
    if (alphaNumeric) {
      return vectorMapAllowedRegions.includes(parseInt(alphaNumeric));
    }
  });
  useEffect(() => {
    if (zoomableTerritories.length > 0) {
      mapRef?.current?.setFocus({
        regions: zoomableTerritories,
        animate: true,
      });
    }
  }, []);
  useEffect(() => {
    setIsMobile(screenWidth < 1176);
  }, [screenWidth]);
  return (
    <Container fluid>
      <Row
        style={{
          background: "white",
          minHeight: "450px",
        }}
      >
        <Col className="px-0">
          <WorldMap
            mapRef={mapRef}
            regionStyle={regionStyle}
            onRegionTipShow={onRegionTipShow}
            backgroundColor="#CAD7F4"
            regionsSelectable={true}
            regionsSelectableOne={true}
            zoomAnimate={true}
            className="vector-map-license-scope"
          />
        </Col>
      </Row>

      <Row className="license-kpi-territories-map-footer-row justify-content-center">
        <Col xs={12}>
          <>{t("license-kpi.sections.per-1000-streams.boxes.per-territory.legend-title")}</>
        </Col>

        <Col className="map-legend-container-license-kpi">
          {blueDegradedColors.map((color: string, index: number) => {
            return (
              <div className="legend-segment-container-license-kpi" key={index}>
                <div
                  className="colored-legend-element-license-kpi"
                  style={{
                    background: color,
                  }}
                />
                <div className="legend-max-value-element-license-kpi">
                  {numberAbbreviation(Math.round(((index + 1) * highestPricePerStream) / 10))}
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default StreamsPerTerritory;
