import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'

import FiltersComponent from '../../../components/filtersComponent'
import { t } from 'i18next'
import ApplyButtonComponent from '../../../components/filtersComponent/applyButtonComponent'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  fetchCataloguesAsync,
  fetchDistributionsAsync,
  fetchMapDataDistributionRoyaltiesAsync,
  fetchDistributionsWorksAsync,
  fetchOfferExploitationPeriodsAsync,
  fetchDspExploitationPeriodsAsync,
  fetchWorksSuggestAsync,
  fetchRightsTypesAsync,
  fetchCatalogsSuggestAsync,
  fetchDistributionsAmountAsync,
  loadPreviousSearchParams,
  loadSelectedDistribution,
  loadSelectedCatalogs,
  loadSelectedWorks
 } from '../../../slices/distributionsRoyaltiesSlice'

import {
  Distribution,
  DistributionRoyaltiesFiltersParams,
  DistributionRoyaltiesSearchParams,
  MultiSelectAutoCompleteItem,
} from '../../../types/distributionsRoyaltiesTypes'
import { SelectItem } from '@mantine/core/lib/Select/types'
import { allowedStatus } from '../../../components/topStatusBar'
import { fetchRepertoiresAsync, setBreadcrumb, setToasterState, setSelectedRepertoire } from '../../../slices/appContextSlice'
import { FF_DISTRIBUTION_ROYALTIES } from '../../../providers/configurationProvider'

const DistributionHeader = () => {
  const dispatch = useAppDispatch()
  const mandator = useAppSelector((state) => state.appContext.mandator)
  const societyId = useAppSelector((state) => state.appContext.selectedSisterSociety.sisterSocietyId)
  const distributionsList = useAppSelector(
    (state) => state.distributionRoyalties.filters.distributions
  )
  const mandatorStartDate = useAppSelector((state) => state.appContext.mandatorStartDate)


  const repertoiresStatus = useAppSelector(
    (state) => state.appContext.repertoires.status
  )

  const repertoiresData = useAppSelector(
    (state) => state.appContext.repertoires.data
  )

  const allRepertoiresOptionsData: any[] = [{
    code: 'all-repertoires',
    label: 'All repertoires',
    value: 'All repertoires',
  }]

  const distributionsStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.distributions
  )
  const selectedRepertoire = useAppSelector(
    (state) => state.appContext.selectedRepertoire
  )
  const selectedDistribution = useAppSelector(
    (state) => state.distributionRoyalties.searchParams.distribution
  )

  const distributionsWorksStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.works
  )

  const cataloguesStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.catalogues
  )

  const rightsTypesStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.rightsTypes
  )
  const worksSuggestStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.worksSuggest
  )

  const worksSuggest = useAppSelector(
    (state) => state.distributionRoyalties.content.worksSuggest
  )

  const catalogsSuggestStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.catalogsSuggest
  )

  const catalogsSuggest = useAppSelector(
    (state) => state.distributionRoyalties.content.catalogsSuggest
  )

  useEffect(() => {
    dispatch(setBreadcrumb({
      title: t('breadcrumb.distribution-royalties'),
      showHome: true,
      featKey: FF_DISTRIBUTION_ROYALTIES
    }));
    // manages initial boxes fetch
    const shouldNotMakeInitialPageFetch =
      mandator === null || distributionsStatus !== 200 || !societyId

    if (shouldNotMakeInitialPageFetch) {
      return
    }
    if (!mandator) return;

    dispatch(fetchDistributionsAmountAsync(getSearchParams('amount')))
    dispatch(fetchDistributionsWorksAsync(getSearchParams('works')))
    dispatch(fetchCataloguesAsync(getSearchParams('catalogues')))
    dispatch(fetchRightsTypesAsync(getSearchParams('rightTypes')))
    dispatch(
      fetchOfferExploitationPeriodsAsync(getSearchParams('offerExploitationPeriods'))
    )
    dispatch(
      fetchDspExploitationPeriodsAsync(getSearchParams('dspExploitationPeriods'))
    )
    dispatch(fetchMapDataDistributionRoyaltiesAsync(getSearchParams('map')))

    dispatch(loadPreviousSearchParams())
  }, [mandator, distributionsStatus])

  useEffect(() => {
    if (mandator === null || distributionsStatus == 200) {
      return
    }

    dispatch(fetchDistributionsAsync({ mandatorId: mandator, startDate: mandatorStartDate }))
  }, [mandator])

  useEffect(() => {
    if (mandator === null) {
      return
    }
    dispatch(fetchRepertoiresAsync({ societyId: societyId }))
  }, [mandator, societyId])

  useEffect(() => {
    if (selectedDistribution === -1 && distributionsList.length > 0) {
      dispatch(loadSelectedDistribution(distributionsList[0].number))
    } else if (
      distributionsList.find(
        (i: Distribution) => i.number === selectedDistribution
      )
    ) {
      dispatch(loadSelectedDistribution(selectedDistribution))
    }
  }, [dispatch, distributionsList, selectedDistribution, distributionsStatus])

  const handleDistributionOnClick = (distribution: SelectItem) => {
    dispatch(loadSelectedDistribution(parseInt(distribution.code)))
  }
  const handleRepertoireOnClick = (repertoire) => {
    dispatch(setSelectedRepertoire(repertoire.code))
  }

  const getSearchParams = (
    targetBox:
      | 'works'
      | 'catalogues'
      | 'rightTypes'
      | 'map'
      | 'offerExploitationPeriods'
      | 'dspExploitationPeriods'
      | 'amount'
  ) => {
    const params: DistributionRoyaltiesSearchParams = {
      societyId,
      repertoireId: selectedRepertoire ? selectedRepertoire : 'all-repertoires'
    }

    params['workCodes'] = selectedWorks.map((item) => item.code).join(',')
    params['catalogCodes'] = selectedCatalogs.map((item) => item.code).join(',')
    params['distributionId'] = selectedDistribution
    switch (targetBox) {
      case 'works':
        params['sortField'] = 'generatedAmount'
        params['sortOrder'] = 'DESC'
        break
      case 'catalogues':
        params['sortField'] = 'generatedAmount'
        params['sortOrder'] = 'DESC'
        break
      case 'offerExploitationPeriods':
        params['type'] = 'offer'
        break
      case 'dspExploitationPeriods':
        params['type'] = 'dsp'
        break
    }
    return params
  }

  const getFilterParams = (searchText: string) => {
    const params: DistributionRoyaltiesFiltersParams = {
      societyId: societyId,
      distributionId: selectedDistribution,
      searchText: searchText,
    }
    return params
  }

  const selectedWorks = useAppSelector(
    (state) => state.distributionRoyalties.searchParams.works
  )
  const handleWorksChange = (selectedWorks: MultiSelectAutoCompleteItem[]) => {
    dispatch(loadSelectedWorks(selectedWorks))
  }

  const handleFetchWorksData = (searchValue: string) =>
    fetchWorksSuggestAsync(getFilterParams(searchValue))

  const worksSuggestMapped: MultiSelectAutoCompleteItem[] = worksSuggest.map(
    (workSuggest) => {
      return {
        label: `${workSuggest.title} - ${workSuggest.id}`,
        value: `${workSuggest.title} - ${workSuggest.id}`,
        code: workSuggest.id,
      }
    }
  )

  const selectedCatalogs = useAppSelector(
    (state) => state.distributionRoyalties.searchParams.catalogs
  )
  const handleCatalogsChange = (
    selectedCatalogs: MultiSelectAutoCompleteItem[]
  ) => {
    dispatch(loadSelectedCatalogs(selectedCatalogs))
  }

  const handleFetchCatalogsData = (searchValue: string) =>
    fetchCatalogsSuggestAsync(getFilterParams(searchValue))

  const catalogsSuggestMapped: MultiSelectAutoCompleteItem[] =
    catalogsSuggest.map((catalogSuggest) => {
      return {
        label: catalogSuggest.name,
        value: `${catalogSuggest.name} - ${catalogSuggest.id}`,
        code: catalogSuggest.id,
      }
    })

  const [searchClicked, setSearchClicked] = useState<boolean>(false)
  const handleSearchClick = () => {
    setSearchClicked((prevSearchClicked) => !prevSearchClicked)
    dispatch(fetchCataloguesAsync(getSearchParams('catalogues')))
    dispatch(fetchRightsTypesAsync(getSearchParams('rightTypes')))
    dispatch(fetchDistributionsWorksAsync(getSearchParams('works')))
    dispatch(fetchMapDataDistributionRoyaltiesAsync(getSearchParams('map')))
    dispatch(
      fetchOfferExploitationPeriodsAsync(getSearchParams('offerExploitationPeriods'))
    )
    dispatch(
      fetchDspExploitationPeriodsAsync(getSearchParams('dspExploitationPeriods'))
    )
    dispatch(fetchDistributionsAmountAsync(getSearchParams('amount')))
    dispatch(loadPreviousSearchParams())
  }

  const pageComponentsStatusArray: number[] = [
    distributionsStatus,
    distributionsWorksStatus,
    cataloguesStatus,
    rightsTypesStatus,
  ]

  const filtersStatusArray = [distributionsStatus]

  const filtersError = !filtersStatusArray?.every((status) => {
    return allowedStatus.includes(status)
  })

  useEffect(() => {
    dispatch(
      setToasterState({
        status: filtersError,
        nature: 'error',
        messageTranslationKey: 'errorPage.500.message',
      })
    )
    return () => {
      dispatch(
        setToasterState({
          status: false,
        })
      )
    }
  }, [filtersError])

  const CollapseComponent = () => (
    <>
      <ListGroup.Item className={'flex-fill d-flex justify-content-center p-1'}>
        <FiltersComponent
          statusArray={pageComponentsStatusArray}
          textPlaceholder={`${t(
            'distribution-royalties.filters.works-placeholder'
          )}`}
          noDataMessage={t('distribution-royalties.filters.no-data')}
          filterType={'multiSelectAutoComplete'}
          widthFilter={'330px'}
          data={worksSuggestMapped}
          previousData={selectedWorks}
          handleComponentChange={handleWorksChange}
          handleActionFetch={handleFetchWorksData}
          status={worksSuggestStatus}
          searchClicked={searchClicked}
        />
      </ListGroup.Item>
      <ListGroup.Item className={'flex-fill d-flex justify-content-center p-1'}>
        <FiltersComponent
          statusArray={pageComponentsStatusArray}
          textPlaceholder={`${t(
            'distribution-royalties.filters.catalogue-placeholder'
          )}`}
          noDataMessage={t('distribution-royalties.filters.no-data')}
          filterType={'multiSelectAutoComplete'}
          widthFilter={'330px'}
          data={catalogsSuggestMapped}
          previousData={selectedCatalogs}
          handleComponentChange={handleCatalogsChange}
          handleActionFetch={handleFetchCatalogsData}
          status={catalogsSuggestStatus}
          searchClicked={searchClicked}
        />
      </ListGroup.Item>
    </>
  )

  return (
    <>
      <ListGroup
        horizontal={'md'}
        className={'w-100'}
        style={{ backgroundColor: '#FFF' }}
      >
        <ListGroup.Item
          className={'flex-fill d-flex justify-content-center p-1'}
        >
          <FiltersComponent
            keepInitialDataSort
            statusArray={pageComponentsStatusArray}
            textPlaceholder={`${t(
              'distribution-royalties.filters.distribution-placeholder'
            )}`}
            data={distributionsList ? distributionsList.map((distribution: Distribution) => {
              const mappedData: SelectItem = {
                label: distribution.date + ' N°' + distribution.number,
                value: distribution.date + ' N°' + distribution.number,
                code: distribution.number.toString(),
              }
              return mappedData
            }) : []}
            status={distributionsStatus}
            selectedValue={selectedDistribution.toString()}
            filterType={'monoSelect'}
            widthFilter={'330px'}
            handleOnClick={handleDistributionOnClick}
          />
        </ListGroup.Item>
        {CollapseComponent()}
        <ListGroup.Item
          className={'flex-fill d-flex justify-content-center p-1'}
        >
          <FiltersComponent
            keepInitialDataSort
            statusArray={pageComponentsStatusArray}
            textPlaceholder={`${t(
              'form.filters.repertoires'
            )}`}
            data={repertoiresData ? allRepertoiresOptionsData.concat(repertoiresData.map((item) => {
              const mappedData: SelectItem = {
                label: item.label,
                value: item.label,
                code: item.code,
              }
              return mappedData
            })) : allRepertoiresOptionsData}
            status={repertoiresStatus}
            selectedValue={selectedRepertoire}
            filterType={'monoSelect'}
            widthFilter={'330px'}
            handleOnClick={handleRepertoireOnClick}
          />
        </ListGroup.Item>
        <ListGroup.Item
          className={
            'flex-column text-center justify-content-center p-1 px-2 border-end-0'
          }
        >
        </ListGroup.Item>
        <ListGroup.Item
          className={
            'flex-column text-center justify-content-center p-1 px-2 border-end-0'
          }
        >
          <ApplyButtonComponent
            disabled={pageComponentsStatusArray.includes(-1)}
            onClickHandler={handleSearchClick}
          />
        </ListGroup.Item>
      </ListGroup>
    </>
  )
}

export default DistributionHeader
