export class BiMap<K, V> {
  private keyToValue: Map<K, V> = new Map()
  private valueToKey: Map<V, K> = new Map()

  set(key: K, value: V): void {
    // Remove old mappings if they exist
    if (this.keyToValue.has(key)) {
      const oldValue = this.keyToValue.get(key)
      this.valueToKey.delete(oldValue as V)
    }
    if (this.valueToKey.has(value)) {
      const oldKey = this.valueToKey.get(value)
      this.keyToValue.delete(oldKey as K)
    }

    // Set new mappings
    this.keyToValue.set(key, value)
    this.valueToKey.set(value, key)
  }
  getAllKeys(): K[] {
    return Array.from(this.keyToValue.keys())
  }

  getAllValues(): V[] {
    return Array.from(this.valueToKey.keys())
  }
  getKey(value: V): K | undefined {
    return this.valueToKey.get(value)
  }

  getValue(key: K): V | undefined {
    return this.keyToValue.get(key)
  }

  deleteByKey(key: K): void {
    const value = this.keyToValue.get(key)
    if (value !== undefined) {
      this.valueToKey.delete(value)
    }
    this.keyToValue.delete(key)
  }

  deleteByValue(value: V): void {
    const key = this.valueToKey.get(value)
    if (key !== undefined) {
      this.keyToValue.delete(key)
    }
    this.valueToKey.delete(value)
  }

  hasKey(key: K): boolean {
    return this.keyToValue.has(key)
  }

  hasValue(value: V): boolean {
    return this.valueToKey.has(value)
  }

  clear(): void {
    this.keyToValue.clear()
    this.valueToKey.clear()
  }
}
